import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';

function TextArea(props) {
  const { notes, setNotes } = props;
  return (
    <div>
      <TextField
        onChange={(e) => setNotes(e.target.value)}
        id="outlined-multiline-static"
        label="Assumptions"
        multiline
        rows={4}
        fullWidth
        defaultValue={(notes) || ''}
        variant="outlined"
        style={{ FormControl: 'mb-4' }}
      />
    </div>
  );
}

TextArea.propTypes = {
  notes: PropTypes.string.isRequired,
  setNotes: PropTypes.func.isRequired,
};

export default TextArea;
