import React from 'react';
import { ListItem, ListItemIcon } from '@material-ui/core';
import { Logout } from '@mui/icons-material';
import { ListItemText } from '@mui/material';
import { auth, STRING_CONF } from '../../../pojo-helpers/pojo-helper';

function SignOut() {
  return (
    <ListItem button onClick={() => auth.signOut()}>
      <ListItemIcon>
        <Logout />
      </ListItemIcon>
      <ListItemText primary={STRING_CONF.USER_LOGOUT_TEXT} />
    </ListItem>
  );
}

export default SignOut;
