import React from 'react';
import {
  TextField, Tooltip, ClickAwayListener,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { westlandMathVerificationText } from '../../../pojo-helpers/pojo-helper';

function MathVerificationEntry(props) {
  const { month, amount } = props;
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
  };
  const classes = westlandMathVerificationText();
  return (
    <div className="mt-4">
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title="This field is only for viewing your work"
        >
          <TextField
            onClick={handleTooltipOpen}
            className={classes.root}
            id={`${month}-math-id`}
            label={capitalize(month)}
            value={amount || 0}
            InputProps={{
              readOnly: true,
            }}
            variant="outlined"
          />
        </Tooltip>
      </ClickAwayListener>
    </div>
  );
}
MathVerificationEntry.propTypes = {
  month: PropTypes.string.isRequired,
  amount: PropTypes.string.isRequired,
};

export default MathVerificationEntry;
