export const BudgetTypeEnum = {
  Estimate: 'estimate',
  FlatContract: 'flat contract',
  FlatPercentIncrease: 'flat percent increase',
  PerItemContract: 'per item contract',
  Seasonal: 'seasonal',
  PerItemVariable: 'per item variable',
  Periodic: 'periodic',
  Interval: 'interval',
};

export const BreakdownEnum = {
  Weekly: 'weekly',
  Monthly: 'monthly',
  Quarterly: 'quarterly',
  Annually: 'annually',
};

export const BreakdownEnumNoQ = {
  Weekly: 'weekly',
  Monthly: 'monthly',
  Annually: 'annually',
};

export const CompletionStatus = {
  Approved: 100,
  Complete: 200,
  Incomplete: 300,
  Rejected: 400,
};

export const CompletionSuccess = [
  CompletionStatus.Approved, CompletionStatus.Complete,
];

export const CompletionFailure = [
  CompletionStatus.Incomplete, CompletionStatus.Rejected,
];

export const IntervalEnum = {
  'Every Other': 'every other',
  Quarterly: 'quarterly',
  Quadrimesterly: 'four-month period',
  Biannually: 'biannually',
};

export const PerItemVariableTypes = {
  TotalUnits: 'totalUnits',
  Turns: 'turns',
};

/*
 The first value in the array is the "Good" zone. This means that any value within this percentage range will be scal
 */
export const Gradient = {
  Good: {
    start: -0.01,
    end: -0.1,
    interval: -0.0025,
    chromaticStart: 0.1,
    chromaticEnd: 1,
    // [ (End - Start) / Interval ] + 1
    // .map((item) => item * interval + start
    range: Array.from(Array(((-0.05 - (-0.02)) / -0.005) + 1).keys())
      .map((item) => item * -0.005 + -0.02),
  },
  Bad: {
    start: 0.05,
    end: 0.10,
    interval: 0.01,
    chromaticStart: 0.5,
    chromaticEnd: 1,
    // [ (End - Start) / Interval ] + 1
    // .map((item) => item * interval + start
    range: Array.from(Array(((0.1 - 0.05) / 0.01) + 1).keys())
      .map((item) => item * 0.01 + 0.05),
  },
};

export const MonthsEnum = {
  January: 0,
  February: 1,
  March: 2,
  April: 3,
  May: 4,
  June: 5,
  July: 6,
  August: 7,
  September: 8,
  October: 9,
  November: 10,
  December: 11,
};

export const MonthsEnumReverse = {
  0: 'January',
  1: 'February',
  2: 'March',
  3: 'April',
  4: 'May',
  5: 'June',
  6: 'July',
  7: 'August',
  8: 'September',
  9: 'October',
  10: 'November',
  11: 'December',
};

export const TutorialNames = {
  BudgetFormTutorial: 'budgetFormTutorial',
  GlTutorial: 'GlTutorial',
  HamburgerMenu: 'HamburgerTutorial',
  LoginTutorial: 'loginTutorial',
};

export const TutorialSteps = {
  initialStep: 0,
  loginSteps: [
    {
      element: '.Global',
      intro: `Hi, thanks for stopping by! We noticed you haven't used this application before.
       We offer a helpful tutorial, if you'd like to learn about our application. You can always close
       these tips and we won't show up again.`,
      tooltipClass: 'w-auto',
    }, {
      element: '#login-button',
      intro: 'In order to get started, you must first sign in with Google and Authenticate using your Westland Google account.',
      tooltipClass: 'w-auto',
    },
  ],
  glForm: [
    {
      element: '.Global',
      intro: 'This is your Dashboard, your GLs can be located here.',
      tooltipClass: 'w-auto',
    }, {
      element: '#property-notification',
      intro: 'The property you are viewing is shown on the top right corner.',
      tooltipClass: 'w-auto',
    }, {
      element: '#hamburger-menu',
      intro: `Need to change your property? Not a problem! To change the community you are viewing, open the side panel menu.
      You can explore this later, for now we'll demonstrate the rest of the GL Form`,
      tooltipClass: 'w-auto',
    }, {
      element: '#tooltip-tut',
      intro: 'If you see these symbols, you can click or hover over them to learn more about it!',
      tooltipClass: 'w-auto',
    }, {
      element: '#card-panel-0',
      intro: 'This is your projected expenses. It will adjust with your entries.',
      tooltipClass: 'w-auto',
    }, {
      element: '#card-panel-1',
      intro: 'The completion rate is to keep you on track, let you know how you’re doing, and to show you how much is left.',
      tooltipClass: 'w-auto',
    }, {
      element: '#filter-bar',
      intro: 'Search for GL codes by typing in its name or number!',
      tooltipClass: 'w-auto',
    }, {
      element: '#filter-menu-icon',
      intro: 'You can also optionally filter by status [Approved, Completed, Incomplete, Rejected]',
      tooltipClass: 'w-auto',
    }, {
      element: '#actuals-header',
      intro: 'Your Actuals come from Yardi. Keep in mind, since December hasn\'t passed yet, you\'re seeing the trailing twelve months.',
      tooltipClass: 'w-auto',
    }, {
      element: '#budget-categories',
      intro: 'These are the budget categories. On the T12, this is called a Sum Category.',
      tooltipClass: 'w-auto',
    }, {
      element: '#expand-row',
      intro: 'Click the dropdown to expand the "parent" gl. You can click any of the GLs in a category to view it\'s budget',
      tooltipClass: 'w-auto',
    },
  ],
  hamburgerMenu: [{
    element: '.Global',
    intro: 'This is your user menu! Some helpful features are located here.',
    tooltipClass: 'w-auto',
  }, {
    element: '#select-prop',
    intro: `Click "Select Property", and find the property you need to switch to. 
    You will only have access to your communities, not all. This tutorial will continue on, but you can test it if you'd like`,
    tooltipClass: 'w-auto',
  }],
  budgetForm: [{
    element: '.Global',
    intro: 'This is the budget form. You will fill out your calculations here',
    tooltipClass: 'w-auto',
  }, {
    element: '#historicals-metric-view',
    intro: 'All GL Forms have historical information, how much was spent last year for this GL. This helps with calculations',
    tooltipClass: 'w-auto',
  }, {
    element: '#projections-metric-view',
    intro: 'All GL Forms will have the Projections chart based on the numbers entered.',
    tooltipClass: 'w-auto',
  }, {
    element: '#notes-area',
    intro: 'You can write notes and reasons behind your entries in the notes column. Your regionals can view them later',
    tooltipClass: 'w-auto',
  }, {
    element: '#math-verification-column',
    intro: 'On all GL Forms, there is a monthly breakdown. This cannot be edited, it\'s used to view your work!',
    tooltipClass: 'w-auto',
  }, {
    element: '#budget-type',
    intro: 'This is the budget type. There are there a several different types that help you calculate in different ways',
    tooltipClass: 'w-auto',
  }, {
    element: '#button-exit',
    intro: 'Exit without Saving will remove all data entered and return you back to the GL Form',
    tooltipClass: 'w-auto',
  }, {
    element: '#button-incomplete',
    intro: 'Save as incomplete is used when you’ve partially entered information and you want to come back to enter more at a later date.',
    tooltipClass: 'w-auto',
  }, {
    element: '#button-complete',
    intro: 'Save as Complete is used when you’ve entered all information and do not need to add anything else.',
    tooltipClass: 'w-auto',
  }],
  stepsEnabled: true,
  options: { hideNext: false, doneLabel: 'Got it!' },
  onExit: (tutorialName) => localStorage.setItem(tutorialName, '0'), // Use this to disable in localStorage?
};

export const EMPTY_PROPERTY_DATA = { units: 0, turns: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] };

export const MONTHS = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];

export const REVIEW_COLUMNS = [
  {
    align: 'left',
    name: 'GL Code',
    minWidth: '140',
  }, {
    align: 'left',
    name: 'Property Code',
    minWidth: '190',
  }, {
    align: 'left',
    name: 'Description',
    minWidth: '190',
  }, {
    align: 'left',
    name: 'Actuals',
    minWidth: '190',
  }, {
    align: 'left',
    name: 'Projections',
    minWidth: '190',
  }, {
    align: 'left',
    name: 'Variance',
    minWidth: '190',
  },
];

export const REVIEW_PAGE_COLUMNS = [
  {
    align: 'center',
    name: '',
    minWidth: '70px',
  },
  {
    align: 'left',
    name: 'GL Code',
    minWidth: '100px',
  }, {
    align: 'left',
    name: 'Description',
    minWidth: '100px',
  }, {
    align: 'right',
    name: '2021 Actuals',
    minWidth: '10px',
  }, {
    align: 'right',
    name: '2022 Projections',
    minWidth: '140px',
  }, {
    align: 'right',
    name: 'Variance',
    minWidth: '140px',
  }, {
    align: 'left',
    name: 'Notes',
    minWidth: '250px',
  },
];

export const COLUMNS = [
  {
    align: 'center',
    minWidth: 140,
    name: 'GL Code',
    id: '',
  },
  {
    align: 'left',
    minWidth: 190,
    name: 'Description',
    id: '',
  },
  {
    align: 'left',
    minWidth: 190,
    name: 'Notes',
    id: '',
  },
  {
    align: 'right',
    minWidth: 146,
    name: '2021 Actuals',
    id: 'actuals-header',
  },
  {
    align: 'right',
    minWidth: 146,
    name: '2022 Projections',
    id: '',
  },
  {
    align: 'right',
    minWidth: 135,
    name: '21-22 Variance',
    id: '',
  },
  {
    align: 'right',
    minWidth: 120,
    name: 'January',
    id: '',
  },
  {
    align: 'right',
    minWidth: 120,
    name: 'February',
    id: '',
  },
  {
    align: 'right',
    minWidth: 120,
    name: 'March',
    id: '',
  },
  {
    align: 'right',
    minWidth: 120,
    name: 'April',
    id: '',
  },
  {
    align: 'right',
    minWidth: 120,
    name: 'May',
    id: '',
  },
  {
    align: 'right',
    minWidth: 120,
    name: 'June',
    id: '',
  },
  {
    align: 'right',
    minWidth: 120,
    name: 'July',
    id: '',
  },
  {
    align: 'right',
    minWidth: 120,
    name: 'August',
    id: '',
  },
  {
    align: 'right',
    minWidth: 120,
    name: 'September',
    id: '',
  },
  {
    align: 'right',
    minWidth: 120,
    name: 'October',
    id: '',
  },
  {
    align: 'right',
    minWidth: 120,
    name: 'November',
    id: '',
  },
  {
    align: 'right',
    minWidth: 120,
    name: 'December',
    id: '',
  },
];
