/* eslint-disable class-methods-use-this */
import React from 'react';
import BaseTypeMap from '../BaseTypeMap/BaseTypeMap';
import { BreakdownEnum, Calculator, MONTHS } from '../../../pojo-helpers/pojo-helper';
import { Estimates } from '../../BudgetTypes/budget-types-index';

class EstimatesBaseTypeMap extends BaseTypeMap {
  constructor(...args) {
    super(...args);
  }

  calculator(values, valueBreakdown) {
    let results = this.emptySet();
    values.forEach((value, index) => {
      let singleEstimateValue;
      const { breakdown } = valueBreakdown[index];
      switch (breakdown) {
        case '':
          singleEstimateValue = this.emptySet();
          break;
        case BreakdownEnum.Weekly:
          singleEstimateValue = this.weeklyBreakdown(value);
          break;
        case BreakdownEnum.Quarterly:
          singleEstimateValue = this.quarterlyBreakdown(value);
          break;
        case BreakdownEnum.Monthly:
          singleEstimateValue = this.monthlyBreakdown(value);
          break;
        case BreakdownEnum.Annually:
          singleEstimateValue = this.annualBreakdown(value);
          break;
        default:
          throw new Error(`${JSON.stringify(breakdown)}: Unknown breakdown type`);
      }
      results = this.mergeSets(results, singleEstimateValue);
    });
    return results;
  }

  display(values, valueBreakdown, handleNumberChange, handleSelectChange, generalData, setValues, setValueBreakdown) {
    return (
      <Estimates
        values={values}
        valueBreakdown={valueBreakdown}
        handleNumberChange={handleNumberChange}
        handleSelectChange={handleSelectChange}
        labels={this.label.labels}
        labelHelpers={this.label.labelHelpers}
        setValues={setValues}
        setValueBreakdown={setValueBreakdown}
      />
    );
  }

  weeklyBreakdown(values) {
    const { numberFormat } = values;
    return MONTHS.map((month, index) => ({
      month,
      numericAmount: numberFormat * Calculator.getNumWeeksForMonth(
        new Date().getFullYear(),
        index,
      ),
      amount:
        Calculator.convertToCurrency(
          numberFormat * Calculator.getNumWeeksForMonth(
            new Date().getFullYear(),
            index,
          ),
        ),
    }));
  }

  quarterlyBreakdown(values) {
    const { numberFormat } = values;
    return MONTHS.map((month) => ({
      month,
      numericAmount: (numberFormat * 4) / MONTHS.length,
      amount: (Calculator.convertToCurrency((numberFormat * 4) / MONTHS.length)),
    }));
  }

  monthlyBreakdown(values) {
    const { numberFormat } = values;

    return MONTHS.map((month) => ({
      month,
      numericAmount: Calculator.convertToFloat(numberFormat),
      amount: Calculator.convertToCurrency(numberFormat),
    }));
  }

  annualBreakdown(values) {
    const { numberFormat } = values;

    const amountPerMonth = (Calculator.convertToFloat(numberFormat) / MONTHS.length);
    return MONTHS.map((month) => ({
      month,
      numericAmount: amountPerMonth,
      amount:
        Calculator.convertToCurrency(amountPerMonth),
    }));
  }
}
export default EstimatesBaseTypeMap;
