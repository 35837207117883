/**
 * React Requirements
 */
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { onAuthStateChanged } from '@firebase/auth';
import { StylesProvider } from '@material-ui/core';
import 'intro.js/introjs.css';
// Used to handle budget associations.
// eslint-disable-next-line no-unused-vars
import MapHandler from './components/JsonTypeMapper/type-mapper-index';

/**
 * Page Imports
 */
import BudgetForm from './components/BudgetForm/BudgetForm/BudgetForm';
import GLForm from './components/GLTable/GLForm/GLForm';
import AxiosGet from './components/Axios/AxiosGet/AxiosGet';
import LoginForm from './components/LoginForm/LoginForm/LoginForm';

/**
 * Components
 */
import { axiosGet, getActiveProperty, getPropertyData } from './pojo-helpers/AxiosCalls';
import { Container, CenteredLoader } from './components/Helper/helper-index';
import { ErrorContainer, NotFound } from './components/ErrorHandling';
import { auth } from './pojo-helpers/firebase';
import { ReviewTable, ReviewPage } from './components/ReviewProcess';

import { EMPTY_PROPERTY_DATA } from './pojo-helpers/Collections';

function App() {
  // User Data
  const [user, setUser] = React.useState(null);
  const [displayFields, setDisplayFields] = React.useState(false);

  // Loading Data
  const [isLoadingHistorical, setLoadingHistorical] = React.useState(true);

  // Property & Historical Data
  const [propertyData, setPropertyData] = React.useState(EMPTY_PROPERTY_DATA);
  const [returnedHistorical, setReturnedHistorical] = React.useState([{}]);

  // Error Messages
  const [errorMessage, setErrorMessage] = React.useState('None');
  const [hasErrorProperty, setErrorProperty] = React.useState(false);
  const [hasErrorHistorical, setErrorHistorical] = React.useState(false);

  // Helper functions
  const reset = () => { setLoadingHistorical(true); setErrorHistorical(false); setErrorProperty(false); };
  const fetchData = () => { axiosGet(setLoadingHistorical, setErrorHistorical, setReturnedHistorical, { }, process.env.REACT_APP_HISTORICALS, 'historicals'); };
  const fetchDataCalls = async () => {
    fetchData();
    const activeProperty = await getActiveProperty(setErrorProperty, setErrorMessage);
    await getPropertyData(activeProperty, setPropertyData);
  };
  // Bad idea, just split into two firebase projects in the future.

  // Wrapper for Reset + FetchData for failure state
  const retryButton = async () => {
    reset();
    await fetchDataCalls();
  };

  useEffect(() => {
    onAuthStateChanged(auth, (authUser) => { setUser(authUser); });
  }, []);

  useEffect(async () => {
    if (user) {
      const tokenInfo = (await user.getIdTokenResult()).claims;
      await setDisplayFields((process.env.REACT_APP_IS_DEV === 'TRUE' ? tokenInfo?.dev : true));
      await fetchDataCalls();
    }
  }, [user]);

  return (
    <StylesProvider injectFirst>
      <div>
        {
          user
            ? (
              <Router>
                <Switch>
                  <Route exact path="/">
                    <Container>
                      { displayFields && isLoadingHistorical && <CenteredLoader />}
                      { displayFields && (hasErrorHistorical || hasErrorProperty) && <ErrorContainer errorMessage={errorMessage} retryAction={retryButton} />}
                      { displayFields && !isLoadingHistorical && !hasErrorHistorical && <AxiosGet Component={GLForm} historicals={returnedHistorical} propertyData={propertyData} />}
                    </Container>
                  </Route>
                  <Route exact path="/budgetform">
                    <Container>
                      <AxiosGet Component={BudgetForm} />
                    </Container>
                  </Route>
                  <Route exact path="/login">
                    <LoginForm />
                  </Route>
                  <Route exact path="/reviewtable/:propertyId?">
                    <Container>
                      {isLoadingHistorical && <CenteredLoader />}
                      {(hasErrorHistorical || hasErrorProperty) && <ErrorContainer errorMessage={errorMessage} retryAction={retryButton} />}
                      {!isLoadingHistorical && !hasErrorHistorical && <AxiosGet Component={ReviewTable} historicals={returnedHistorical} />}
                    </Container>
                  </Route>

                  <Route exact path="/reviewPage">
                    <Container>
                      <ReviewPage />
                    </Container>
                  </Route>
                  <Route path="*">
                    <NotFound />
                  </Route>
                </Switch>
              </Router>
            )
            : <LoginForm />
        }
      </div>
    </StylesProvider>
  );
}

export default App;
