import React from 'react';
import PropTypes from 'prop-types';

const BudgetFormHeader = (props) => {
  const { headerData } = props;
  const { GL, budgetType, subtitle } = headerData;

  return (
    <div>
      <div className="flex flex-col">
        <div className="flex justify-between">
          <div className="text-xl">
            {GL}
          </div>
          <div id="budget-type" className="text-xl">
            BUDGET TYPE:
            {` ${budgetType}`}
          </div>
        </div>
        <div className="text-sm text-gray-400 mt-1">{subtitle}</div>
      </div>
      <hr className="border mt-2 mb-4" />
    </div>
  );
};

BudgetFormHeader.propTypes = {
  headerData: PropTypes.shape({
    GL: PropTypes.string.isRequired,
    budgetType: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
  }).isRequired,

};

export default BudgetFormHeader;
