import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { westlandTextField } from '../../../pojo-helpers/pojo-helper';

function NotFound() {
  const history = useHistory();

  const handleNestedClick = () => history.push({
    pathname: '/',
  });
  const classes = westlandTextField();
  return (
    <div className="bg-no-repeat bg-cover bg-not-found-pig bg-center h-screen">
      <div className="pt-48 2xl:pl-36 xl:pl-24 lg:pl-0">
        <div className="font-cash notFoundFont"> $4.04 </div>
        <div className="ml-16 text-3xl">
          We&apos;re sorry, this page is fresh out of budgets.
        </div>

        <div className="ml-16 mt-4 text-3xl">
          Perhaps we can take you
          <Button color="primary" className={classes.notFoundText} onClick={() => handleNestedClick()}>elsewhere?</Button>
        </div>

      </div>
    </div>
  );
}

export default NotFound;
