import React from 'react';
import '../../../App.css';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

const MetricView = (props) => {
  const { metricData, displayComparison } = props;
  const {
    average, blurb, id, tooltip, total,
  } = metricData;
  const { averageBlurb, classResult, number } = average;

  return (
    <div className="card-panel xl:mx-8 lg:mx-8 md:mx-4 sm:mx-2 mx-1 ">
      <div className="card-shadow min-width-full flex items-center flex-col">
        <div className="flex justify-end w-full ml-6 -mt-3">
          <button id="tooltip-tut" type="button" data-tip={tooltip} data-target={id}>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 24 24">
              <path id="info-fill" d="M12.1,24.1a12,12,0,1,0-8.485-3.515A12,12,0,0,0,12.1,24.1ZM13.495,9.982l-3.435.43-.123.57.675.124c.441.1.528.264.432.7l-1.107,5.2c-.291,1.345.157,1.978,1.212,1.978a3.106,3.106,0,0,0,2.2-.9l.132-.624a1.662,1.662,0,0,1-1.029.369c-.412,0-.562-.289-.456-.8l1.5-7.057ZM12.1,8.35a1.5,1.5,0,1,0-1.061-.439A1.5,1.5,0,0,0,12.1,8.35Z" transform="translate(-0.1 -0.1)" fillRule="evenodd" />
            </svg>
          </button>
        </div>
        <div className="flex flex-initial">
          <div className="text-sm font-light">
            {blurb}
          </div>
        </div>
        <div className="flex-initial font-medium text-2xl  mt-2">
          {total}
        </div>
        <div className="flex-initial font-light text-sm mt-4">
          <span style={{ color: classResult }}>
            {displayComparison && number}
          </span>
          {displayComparison && averageBlurb}
        </div>
      </div>
      <ReactTooltip className="tooltip-override" type="dark" multiline />
    </div>
  );
};

MetricView.propTypes = {
  metricData: PropTypes.shape({
    average: PropTypes.shape({
      averageBlurb: PropTypes.string.isRequired,
      classResult: PropTypes.string.isRequired,
      number: PropTypes.string.isRequired,
    }).isRequired,
    blurb: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    tooltip: PropTypes.string.isRequired,
    total: PropTypes.string.isRequired,
  }).isRequired,
  displayComparison: PropTypes.bool,
};

MetricView.defaultProps = {
  displayComparison: true,
};

export default MetricView;
