import React, { useEffect } from 'react';
import {
  Paper, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Button,
} from '@mui/material';
import {
  useHistory,
  useParams,
} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { onAuthStateChanged } from '@firebase/auth';
import {
  getActiveProperty, getProperties, getReviewsByRole, setActiveProperty,
} from '../../../pojo-helpers/AxiosCalls';
import { CenteredLoader } from '../../Helper/helper-index';
import ErrorContainer from '../../ErrorHandling/ErrorContainer/ErrorContainer';
import { auth, Calculator, REVIEW_COLUMNS } from '../../../pojo-helpers/pojo-helper';
import { gradientCategory } from '../../../pojo-helpers/GenericHelpers';
import DataHandler from '../../../pojo-helpers/DataHandler';
import { setHistorical } from '../../../redux/historical';
import PropertyDialogue from '../../User/PropertyDialogue/PropertyDialogue';

function ReviewTable(props) {
  // eslint-disable-next-line react/prop-types
  const { data, historicals } = props;
  const dispatch = useDispatch();
  const params = useParams();
  const historicalRes = DataHandler.returnBucketsHistorical(historicals, data);
  dispatch(setHistorical(historicalRes));

  const history = useHistory();
  const [user, setUser] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [hasErrorProperty, setErrorProperty] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('None');
  const [rows, setRows] = React.useState([]);
  const [openDialogue, setOpenDialogue] = React.useState(false);

  useEffect(async () => {
    if (params.propertyId) {
      const activeProperty = await getActiveProperty();
      if (activeProperty !== params.propertyId) {
        await setActiveProperty(params.propertyId);
        window.location.reload();
      }
    }
    // setActiveProperty()
    onAuthStateChanged(auth, (authUser) => { setUser(authUser); });
  }, []);

  useEffect(async () => {
    if (user) {
      const idToken = await user.getIdTokenResult();
      const properties = await getProperties(setErrorProperty, setErrorMessage);
      setIsLoading(false);
      const currentProperty = await getActiveProperty();
      const reviews = await getReviewsByRole(properties, idToken.claims);
      setRows(reviews.filter((review) => String(review?.property) === String(currentProperty) || review.property === `${currentProperty}`));
    }
  }, [user]);
  return (
    <div>
      <PropertyDialogue open={openDialogue} setOpen={setOpenDialogue} />
      {isLoading && <CenteredLoader />}
      {/* TODO Implement retry */}
      {(hasErrorProperty) && <ErrorContainer errorMessage={errorMessage} retryAction={() => console.log('IMPLEMENT')} />}
      {!isLoading && !hasErrorProperty
      && (
        <div>
          <div className="flex flex-col justify-center items-center">
            <span className="text-3xl">Property Review</span>
            <span className="text-xs text-gray-500">All GL buckets that have been marked as ready for review</span>
            <div className="mt-4">
              <Button onClick={() => setOpenDialogue(!openDialogue)}>Change Properties</Button>
            </div>
          </div>
          <div className="flex justify-center">
            <Paper className="mt-12" sx={{ width: '80%', height: '700px', overflow: 'hidden' }}>
              <TableContainer className="tableSize">
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow style={{ backgroundColor: 'rgb(229, 231, 235)' }}>
                      {REVIEW_COLUMNS.map((column) => (
                        <TableCell
                          className="review-table-borders"
                          key={column.name}
                          align={column.align}
                          style={{ minWidth: column.minWidth, backgroundColor: 'rgb(229, 231, 235)' }}
                        >
                          <span className="text-lg">{column.name}</span>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows
                      .map((row) => (
                        <TableRow hover role="checkbox" tabIndex={-1} key={row.glCode} onClick={() => history.push({ pathname: '/reviewPage', state: { parentGl: row.glCode, property: row.property } })}>
                          <TableCell>
                            {row.glCode}
                          </TableCell>
                          <TableCell>
                            {row.property}
                          </TableCell>
                          <TableCell>
                            {row.description}
                          </TableCell>
                          <TableCell>
                            {Calculator.convertToCurrency(row.actuals)}
                          </TableCell>
                          <TableCell>
                            {Calculator.convertToCurrency(row.projections)}
                          </TableCell>
                          <TableCell
                            style={{
                              color: `${gradientCategory(
                                Calculator.calculatePercent(row.actuals, row.projections),
                              )}`,
                            }}
                          >
                            {(Calculator.calculatePercent(row.actuals, row.projections) * 100).toFixed(2)}
                            %
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </div>
        </div>
      )}
    </div>
  );
}

/*

 */

export default ReviewTable;
