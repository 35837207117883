/* eslint-disable class-methods-use-this */
import React from 'react';
import BaseTypeMap from '../BaseTypeMap/BaseTypeMap';
import MapHandler from '../MapHandler/MapHandler';
import {
  BudgetTypeEnum, Calculator, MONTHS,
} from '../../../pojo-helpers/pojo-helper';
import { Periodic } from '../../BudgetTypes/budget-types-index';

class PeriodicTypeMap extends BaseTypeMap {
  constructor(...args) {
    super(...args);
  }

  calculator(values) {
    return MONTHS.map((month, index) => ({
      month,
      amount: Calculator.convertToCurrency(values[index]),
      numericAmount: Calculator.convertToFloat(values[index]),
    }));
  }

  display(values, _, handleNumberChange) {
    return (
      <Periodic
        handleNumberChange={handleNumberChange}
        values={values}
      />
    );
  }
}

MapHandler.register(
  BudgetTypeEnum.Periodic,
  new PeriodicTypeMap(
    BudgetTypeEnum.Periodic,
    { },
    {
      0: '0.00',
      1: '0.00',
      2: '0.00',
      3: '0.00',
      4: '0.00',
      5: '0.00',
      6: '0.00',
      7: '0.00',
      8: '0.00',
      9: '0.00',
      10: '0.00',
      11: '0.00',
    },
  ),
);

export default PeriodicTypeMap;
