import {
  addOrAdjustLineItem,
  axiosGet, getActiveProperty, pushReviewData, sendEmail,
} from './AxiosCalls';
import { Calculator, CompletionSuccess } from './pojo-helper';
import { fillArrayWithContent } from './GenericHelpers';

function extractCategoricalData(parentGl, budgetData) {
  return budgetData.filter((entry) => entry.glReference.parentGl.glCode === parentGl);
}

function isComplete(budgetData) {
  return (budgetData.length <= 0)
    ? false
    : budgetData.every((entry) => CompletionSuccess.includes(entry.isComplete));
}

function calculateHistoricals(bucket) {
  return bucket.values().reduce((partialAmount, a) => partialAmount
    + a.reduce((monthAmount, b) => monthAmount + b, 0),
  0) || 0;
}

function calculateProjected(bucket, extractedBudget, turns, totalUnits) {
  return extractedBudget.reduce((partialAmount, glItem) => partialAmount
    + Calculator.calculateSubRowIfPresent(glItem, { historicals: bucket.get(glItem.glReference.glCode), turns, totalUnits }).months.reduce((monthAmount, month) => monthAmount + month.numericAmount, 0),
  0) || 0;
}

function calculateLineEntryMonth(bucket, extractedBudget, glCode, turns, totalUnits) {
  const glEntryArray = extractedBudget.filter((glItem) => glItem?.glReference.glCode === glCode);
  if (!(glEntryArray.length > 0 && glEntryArray.length <= 1)) throw new Error('Unexpected glEntry Size');
  const glItem = glEntryArray[0];
  return Calculator.calculateSubRowIfPresent(glItem,
    { historicals: bucket.get(glItem.glReference.glCode), turns, totalUnits });
}

function retrieveGlNotes(bucket, extractedBudget, glCode) {
  const glEntryArray = extractedBudget.filter((glItem) => glItem?.glReference.glCode === glCode);
  if (!(glEntryArray.length > 0 && glEntryArray.length <= 1)) throw new Error('Unexpected glEntry Size');
  const glItem = glEntryArray[0];
  return glItem.notes;
}

function retrieveGlStatus(bucket, extractedBudget, glCode) {
  const glEntryArray = extractedBudget.filter((glItem) => glItem?.glReference.glCode === glCode);
  if (!(glEntryArray.length > 0 && glEntryArray.length <= 1)) throw new Error('Unexpected glEntry Size');
  const glItem = glEntryArray[0];
  return glItem.isComplete;
}

function calculateMonthly(bucket, extractedBudget) {
  try {
    return Calculator.calculateBucketIfPresent({ children: extractedBudget }, bucket);
  } catch (e) {
    return fillArrayWithContent(12, 0);
  }
}

function returnParentDescription(extractedBudgetData) {
  return extractedBudgetData[0].glReference.parentGl.description || 'Unknown';
}

async function reviewHandler(parentGl, historicalData, glCode, turns, totalUnits, user, emailPayload) {
  const property = await getActiveProperty();
  const budgetData = await axiosGet(() => {}, () => {}, () => {}, { });
  const extractedBudgetData = extractCategoricalData(parentGl, budgetData);
  if (!property || !parentGl || extractedBudgetData.length <= 0) {
    console.error('Property || Parent || extractedBudget is invalid');
    return;
  }
  const bucket = historicalData.get(parentGl);

  addOrAdjustLineItem({
    completionStatus: retrieveGlStatus(bucket, extractedBudgetData, glCode),
    glCode,
    property,
    monthly: calculateLineEntryMonth(bucket, extractedBudgetData, glCode, turns, totalUnits),
    notes: retrieveGlNotes(bucket, extractedBudgetData, glCode),
    reviewId: `${parentGl}${property}`,
    user: user.email,
  });

  if (!isComplete(extractedBudgetData)) {
    // await deleteBigQueryReviewData(`${parentGl}${property}`);
    pushReviewData({
      actuals: null,
      assetApproval: '',
      assetNotes: '',
      description: '',
      glCode: parentGl,
      isReady: false,
      monthly: null,
      property,
      projections: null,
      regionalApproval: '',
      regionalNotes: '',
      needsRegional: true,
      needsAsset: true,
    });
    return;
  }
  sendEmail(emailPayload, 'sendRegionalEmail');
  pushReviewData({
    actuals: calculateHistoricals(bucket),
    assetApproval: '',
    assetNotes: '',
    description: returnParentDescription(extractedBudgetData),
    glCode: parentGl,
    isReady: true, // Map if all is complete
    monthly: calculateMonthly(bucket, extractedBudgetData),
    property,
    projections: calculateProjected(bucket, extractedBudgetData, turns, totalUnits),
    regionalApproval: '',
    needsRegional: true,
    needsAsset: true,
  });
}

export default reviewHandler;
