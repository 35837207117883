import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ArrowBackRounded } from '@material-ui/icons';
import { Steps } from 'intro.js-react';
import {
  BudgetTypeEnum,
  capitalizeFirstLetter, STRING_CONF, TutorialNames, TutorialSteps,
} from '../../../pojo-helpers/pojo-helper';
import { SnackbarAlert } from '../../Helper/helper-index';
import {
  BudgetFooter,
  BudgetFormHeader, BudgetTitles,
  MathVerificationColumn,
  MetricViewRow,
  TextArea,
} from '../index';
import MapHandler from '../../JsonTypeMapper/MapHandler/MapHandler';

function createData(isHistorical = false) {
  const location = useLocation();
  const {
    budgetDataType, glCode, description, parentCategory, propertyData, historicals,
  } = location?.state;
  const totalSum = historicals.reduce((partialAmount, a) => partialAmount + a, 0);
  return {
    GL: `${glCode} - ${capitalizeFirstLetter(description)}`,
    glCodeRaw: glCode,
    budgetType: budgetDataType.toUpperCase(),
    historicals,
    totalUnits: propertyData.units,
    totalTurns: propertyData.turns.reduce((prev, curr) => prev + curr),
    turns: propertyData.turns,
    historicalResults: isHistorical ? {
      historicals,
      projectionTotal: totalSum,
      totalUnits: propertyData.units,
    }
      : {},
    subtitle: parentCategory,
  };
}

function BudgetForm() {
  const history = useHistory();
  const location = useLocation();
  const {
    budgetDataType, parentGl, historicals, isComplete, notesData, url, valuesData, valueBreakdownData,
  } = location?.state;
  const historicalData = createData(true);
  const generalData = createData();
  /* Start States */
  const budgetInfo = MapHandler.get(BudgetTypeEnum[budgetDataType]);
  if (budgetInfo === undefined) {
    history.push('/');
    return (<div />);
  }
  // Used in comparison for update. I want to verify that the value changes as well, not that they accidentally "update" it to the same value
  const startingValue = Object.assign(budgetInfo.getValues, valuesData);
  const startingBreakdown = Object.assign(budgetInfo.getValueBreakdown, valueBreakdownData);
  // Used to keep the state if the user has update the entry.
  const [updated, setUpdated] = React.useState(false);
  // Stores the number for breakdowns
  const [values, setValues] = React.useState(Object.assign(budgetInfo.getValues, valuesData));
  // Stores the "method" for the type of breakdown if applicable. I.E : Weekly, Monthly.
  const [valueBreakdown, setValueBreakdown] = React.useState(Object.assign(budgetInfo.getValueBreakdown, valueBreakdownData));
  // Stores the notes
  const [notes, setNotes] = React.useState(notesData);
  const [alert, setAlert] = React.useState({
    alertMessage: '',
    alertSeverity: '',
    open: false,
  });
  const [open, setOpen] = useState({
    openExit: false,
    openComplete: false,
  });

  const checkForUpdate = (original, changedValue, companionStartingValue, companionCurrentValue) => {
    const changed = JSON.stringify(original) !== JSON.stringify(changedValue)
      || JSON.stringify(companionStartingValue) !== JSON.stringify(companionCurrentValue);
    setUpdated(changed);
  };

  const raiseAlert = (alertMessage, alertSeverity) => {
    setAlert({
      alertMessage,
      alertSeverity,
      open: true,
    });
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAlert({
      alertMessage: '',
      alertSeverity: 'warning',
      open: false,
    });
  };
  const handleSelectChange = (event, index = null) => {
    let result = 0;
    let changes = {};
    // Updates the valueBreakdown
    switch (event.target.name) {
      case 'firstMonth':
        result = valueBreakdown[index].lastMonth;
        if (event.target.value > valueBreakdown[index].lastMonth) {
          result = event.target.value;
          raiseAlert(STRING_CONF.ALERT_MESSAGE_MONTH_FIRST, STRING_CONF.ALERT_WARNING);
        }
        changes = {
          ...valueBreakdown[index],
          lastMonth: result,
          [event.target.name]: event.target.value,
        };
        setValueBreakdown(changes);
        break;
      case 'lastMonth':
        result = event.target.value;
        if (event.target.value < valueBreakdown[index].firstMonth) {
          result = valueBreakdown[index].firstMonth;
          raiseAlert(STRING_CONF.ALERT_MESSAGE_MONTH_LAST, STRING_CONF.ALERT_WARNING);
        }
        changes = {
          ...valueBreakdown[index],
          [event.target.name]: result,
        };
        setValueBreakdown(changes);
        break;
      default:
        changes = (index !== null)
          ? {
            ...valueBreakdown[index],
            [event.target.name]: event.target.value,
          }
          : {
            ...valueBreakdown,
            [event.target.name]: event.target.value,
          };
        break;
    }
    if (index !== null) {
      valueBreakdown[index] = changes;
      setValueBreakdown([...valueBreakdown]);
    } else {
      setValueBreakdown(changes);
    }
    checkForUpdate(startingBreakdown, changes, startingValue, values);
  };
  const handleNumberChange = (event, index = null) => {
    const changes = (index !== null)
      ? {
        ...values[index],
        [event.target.name]: event.target.value,
      }
      : {
        ...values,
        [event.target.name]: event.target.value,
      };

    if (index !== null) {
      values[index] = changes;
      setValues([...values]);
    } else {
      setValues(changes);
    }
    checkForUpdate(startingValue, changes, startingBreakdown, valueBreakdown);
  };

  /* End States */

  const projectionArray = budgetInfo.projectionBreakdown(values, valueBreakdown, generalData);
  const projectionTotal = budgetInfo.projectionTotal(values, valueBreakdown, generalData);

  // TODO Change Unit
  const projectionBreakdown = {
    historicals,
    projectionTotal,
    totalUnits: generalData.totalUnits,
  };

  return (
    <div>
      <SnackbarAlert
        alert={alert}
        handleClose={handleClose}
      />
      <ArrowBackRounded
        className="container-corner mb-5"
        style={{ fontSize: '35px' }}
        onClick={() => { if (updated) setOpen({ ...open, openExit: !open.openExit }); else history.push('/'); }}
      />

      <Steps
        enabled={localStorage.getItem(TutorialNames.BudgetFormTutorial) !== '0'}
        steps={TutorialSteps.budgetForm}
        initialStep={TutorialSteps.initialStep}
        options={TutorialSteps.options}
        onExit={() => TutorialSteps.onExit(TutorialNames.BudgetFormTutorial)}
      />

      <div className="grid grid-flow-col">
        <div>
          <BudgetFormHeader headerData={generalData} />
          <BudgetTitles title="Historicals" />
          <div id="historicals-metric-view"><MetricViewRow items={historicalData.historicalResults} displayComparison={false} /></div>
          <div id="budget-entry">{budgetInfo.display(values, valueBreakdown, handleNumberChange, handleSelectChange, generalData, setValues, setValueBreakdown)}</div>
          <BudgetTitles title="Projections" />
          <div id="projections-metric-view"><MetricViewRow blurb={{ annual: STRING_CONF.PROJECTION_METRIC_ANNUAL, monthly: STRING_CONF.PROJECTION_METRIC_MONTHLY, unit: STRING_CONF.PROJECTION_METRIC_UNIT }} items={projectionBreakdown} /></div>
          <div id="notes-area"><TextArea notes={notes} setNotes={setNotes} /></div>
          <div className="h-6" />
        </div>
        <div id="math-verification-column" className=" flex justify-center ml-4">
          <MathVerificationColumn
            projectionTotals={projectionArray}
          />
        </div>
      </div>
      <BudgetFooter
        glInformation={generalData}
        parentGl={parentGl}
        isComplete={isComplete}
        open={open}
        notes={notes}
        setOpen={setOpen}
        updated={updated}
        values={values}
        valueBreakdown={valueBreakdown}
        raiseAlert={raiseAlert}
        url={url}
      />
    </div>
  );
}

export default BudgetForm;
