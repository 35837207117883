import React from 'react';
import {
  InputAdornment, TextField,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { westlandTextField } from '../../../pojo-helpers/pojo-helper';
import Periodic from '../Periodic/Periodic';

function PercentIncrease(props) {
  const {
    handleNumberChange, historicals, label, values,
  } = props;
  const classes = westlandTextField();

  const invalidEntries = historicals.map((entry, index) => ((Math.floor(entry) <= 0) ? index : -1));
  const invalidMonths = invalidEntries.filter((entry) => entry !== -1);
  return (
    <div className="mt-16 mb-16">
      <div className="flex justify-center items-center flex-col">
        <TextField
          type="number"
          className={`${classes.root} p-2 w-64`}
          label={label}
          value={values.percentFormat}
          onChange={handleNumberChange}
          name="percentFormat"
          id="formatted-numberFormat-input"
          variant="outlined"
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        />

        {invalidEntries.length > 0
        && (
        <Periodic
          handleNumberChange={handleNumberChange}
          periodicArray={invalidMonths}
          values={values}
        />
        )}
      </div>

    </div>
  );
}

PercentIncrease.propTypes = {
  handleNumberChange: PropTypes.func.isRequired,
  historicals: PropTypes.arrayOf(PropTypes.number).isRequired,
  label: PropTypes.string.isRequired,
  values: PropTypes.shape({
    percentFormat: PropTypes.string.isRequired,
  }).isRequired,
};

export default PercentIncrease;
