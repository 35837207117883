import React from 'react';
import '../../../App.css';
import PropTypes from 'prop-types';
import { MetricView } from '../../Helper/helper-index';
import { Calculator, STRING_CONF } from '../../../pojo-helpers/pojo-helper';
import { gradientCategory } from '../../../pojo-helpers/GenericHelpers';

function generateAverageData(amount, historicals, historicalDivider = 1) {
  try {
    const historicalSum = historicals.reduce((prev, curr) => prev + curr) / historicalDivider;
    const percentAmount = Calculator.calculatePercent(historicalSum, amount) * 100;
    return {
      percentAmount,
      numericDifference: amount - historicalSum,
      number: `${percentAmount.toFixed(0)}%`,
      averageBlurb: (percentAmount >= 0) ? ' greater than last year' : ' less than last year',
      classResult: gradientCategory(percentAmount / 100, true),
    };
  } catch (e) {
    console.log(e);
    return 0;
  }
}

function MetricViewRow(props) {
  const { blurb, items, displayComparison } = props;
  const metricData = [{
    id: 'Generic-1',
    average: generateAverageData(items.projectionTotal, items.historicals),
    blurb: blurb.annual,
    total: Calculator.convertToCurrency(items.projectionTotal),
    tooltip: STRING_CONF.TOOLTIP_HISTORICAL_ANNUAL,
  },
  {
    id: 'Generic-2',
    average: generateAverageData(items.projectionTotal / 12, items.historicals, 12),
    blurb: blurb.monthly,
    total: Calculator.convertToCurrency(items.projectionTotal / 12),
    tooltip: STRING_CONF.TOOLTIP_HISTORICAL_MONTHLY,
  },
  {
    id: 'Generic-3',
    average: generateAverageData(items.projectionTotal / items.totalUnits, items.historicals, items.totalUnits),
    blurb: blurb.unit,
    total: Calculator.convertToCurrency(items.projectionTotal / items.totalUnits),
    tooltip: STRING_CONF.TOOLTIP_HISTORICAL_UNIT,
  },
  ];
  const metricItems = metricData.map((metricItem) => (
    <div key={metricItem.id} className="flex-initial">
      <MetricView metricData={metricItem} displayComparison={displayComparison} />
    </div>
  ));

  return (
    <div>
      <div className="flex justify-around mb-12">
        {metricItems}
      </div>
    </div>
  );
}

MetricViewRow.defaultProps = {
  blurb: {
    annual: STRING_CONF.HISTORICAL_METRIC_ANNUAL,
    monthly: STRING_CONF.HISTORICAL_METRIC_MONTHLY,
    unit: STRING_CONF.HISTORICAL_METRIC_UNIT,
  },
  displayComparison: true,
};

MetricViewRow.propTypes = {
  blurb: PropTypes.shape({
    annual: PropTypes.string,
    monthly: PropTypes.string,
    unit: PropTypes.string,
  }),
  displayComparison: PropTypes.bool,
  items: PropTypes.shape({
    historicals: PropTypes.arrayOf(PropTypes.number).isRequired,
    projectionTotal: PropTypes.number.isRequired,
    totalUnits: PropTypes.number.isRequired,
  }).isRequired,
};

export default MetricViewRow;
