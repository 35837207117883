import React from 'react';
import {
  Paper, Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import HashMap from 'hashmap';
import { COLUMNS, STRING_CONF } from '../../../pojo-helpers/pojo-helper';
import EnhancedTableToolbar from '../EnhancedTableToolbar/EnhancedTableToolbar';
import Row from '../Row/Row';
import { CompletionStatus } from '../../../pojo-helpers/Collections';

function DataTable(props) {
  const { dataTableEntries, dataTableHistoricals, propertyData } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [filterState, setFilterState] = React.useState({
    filterApproved: false,
    filterComplete: false,
    filterIncomplete: false,
    filterRejected: false,
  });
  /**
   * filterType: Denotes the property to be tested against. This references the BDE.
   * operator: The comparator to use. Currently only '==' is handled.
   * requirement: What ENUM value should the filterType be? EX: glEntry.isComplete {OPERATOR} CompletionStatus.Approved
   * enabled: What filterState should be toggle for the visual display.
   */
  const [filterStatus, setFilterStatus] = React.useState({
    filterApproved: {
      filterType: 'isComplete',
      operator: '==',
      requirement: CompletionStatus.Approved,
      enabled: filterState.filterApproved,
    },
    filterComplete: {
      filterType: 'isComplete',
      operator: '==',
      requirement: CompletionStatus.Complete,
      enabled: filterState.filterComplete,
    },
    filterIncomplete: {
      filterType: 'isComplete',
      operator: '==',
      requirement: CompletionStatus.Incomplete,
      enabled: filterState.filterIncomplete,
    },
    filterRejected: {
      filterType: 'isComplete',
      operator: '==',
      requirement: CompletionStatus.Rejected,
      enabled: filterState.filterRejected,
    },

  });
  // Handles the search bar. Makes use of sessionStorage to keep filter after a page change.
  const [searchValue, setSearchValue] = React.useState(JSON.parse(sessionStorage.getItem(STRING_CONF.SESSION_STORAGE_FILTER)));
  const filterOptionSelect = (event) => {
    // event.target.name should name field on the component
    filterStatus[event.target.name].enabled = event.target.checked;
    const filteredUpdate = filterStatus[event.target.name];
    setFilterStatus({ ...filterStatus, [event.target.name]: filteredUpdate });
    setFilterState({ ...filterState, [event.target.name]: event.target.checked });
  };
  const searchBarUpdate = (event, newValue) => {
    // This helps to keep some persistence during reloads & page swaps
    // without tracking across multiple components.
    sessionStorage.setItem(STRING_CONF.SESSION_STORAGE_FILTER, JSON.stringify(newValue));
    setSearchValue(newValue);
  };
  const filterIconClickOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const filterIconClickClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <EnhancedTableToolbar
        component={Paper}
        id="gl-toolbar"
        className="w-max"
        handleClose={filterIconClickClose}
        handleChange={filterOptionSelect}
        handleSearchChange={searchBarUpdate}
        handleClick={filterIconClickOpen}
        searchValue={searchValue}
        anchorEl={anchorEl}
        setFilterStatus={setFilterStatus}
        filterStatus={filterState}
        searchRows={dataTableEntries}
      />
      <TableContainer className="tableSize" component={Paper}>
        <Table stickyHeader aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              {COLUMNS.map((column) => (
                <TableCell
                  id={column.id}
                  key={column.name}
                  align={column.align}
                  style={{ minWidth: column.minWidth, maxWidth: 20 }}
                >
                  {column.name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataTableEntries.map((row) => (
              <Row
                key={row.parent?.glCode}
                uniqueID={row.parent?.glCode}
                row={row}
                historicals={dataTableHistoricals}
                searchValue={searchValue}
                filters={Object.values(filterStatus).filter((filterItem) => filterItem.enabled)}
                propertyData={propertyData}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

DataTable.defaultProps = {
  dataTableHistoricals: new HashMap(),
};

DataTable.propTypes = {
  dataTableEntries: PropTypes.arrayOf(PropTypes.shape({
    parent: PropTypes.shape({}),
    children: PropTypes.arrayOf(PropTypes.shape({
      budgetType: PropTypes.string,
      budgetData: PropTypes.shape({}),
      glCode: PropTypes.string,
      isComplete: PropTypes.number,
      propertyCode: PropTypes.string,
      description: PropTypes.string,
    })),
  })).isRequired,
  dataTableHistoricals: PropTypes.objectOf(HashMap),
  propertyData: PropTypes.shape({
    units: PropTypes.number,
    turns: PropTypes.arrayOf(PropTypes.number),
  }).isRequired,
};

export default DataTable;
