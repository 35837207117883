import { BreakdownEnum, Gradient, MONTHS } from './Collections';

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function convertDurationToFriendly(duration) {
  switch (duration) {
    case '':
      return '';
    case BreakdownEnum.Weekly:
      return 'Week';
    case BreakdownEnum.Quarterly:
      return 'Quarter';
    case BreakdownEnum.Monthly:
      return 'Month';
    case BreakdownEnum.Annually:
      return 'Annum';
    default:
      throw new Error(`${duration}: Unknown breakdown type`);
  }
}

export function monthNumberToDate(monthNumber) {
  if (typeof monthNumber !== 'number' || monthNumber < 0 || monthNumber > 12) throw new Error(`Expecting number between [0,11] got ${monthNumber}`);
  return capitalizeFirstLetter(MONTHS[monthNumber]);
}

// eslint-disable-next-line no-unused-vars
function getColor(value) {
  // value from 0 to 1
  const hue = ((1 - value) * 120).toString(10);
  return ['hsl(', hue, ',80%,45%)'].join('');
}

function getGradientObjectByPercent(percentDifference) {
  if (percentDifference >= Gradient.Bad.start) return Gradient.Bad;
  if (percentDifference <= Gradient.Good.start) return Gradient.Good;
  return false;
}

export function gradientCategory(percentDifference, isFinished = true) {
  if (!isFinished) return 'black';
  const gradientInformation = getGradientObjectByPercent(percentDifference);
  if (!gradientInformation) return 'black';
  const closest = gradientInformation.range.reduce((prev, curr) => (Math.abs(curr - percentDifference) < Math.abs(prev - percentDifference) ? curr : prev));
  const index = gradientInformation.range.indexOf(closest);
  const hslIntervalValue = (gradientInformation.chromaticEnd - gradientInformation.chromaticStart) / gradientInformation.range.length;
  const hslArrayList = Array.from(Array(gradientInformation.range.length + 1).keys()).map((item) => (item * hslIntervalValue) + gradientInformation.chromaticStart);
  return getColor(hslArrayList[index]);
}
//
function getSentenceToCompleteWord(sentence, count) {
  const splitSentence = sentence.split(' ');
  let lastValidWord = 0;
  splitSentence.reduce((prev, current, index) => {
    const sum = prev + current.length;
    if (sum <= count) lastValidWord = index;
    return sum;
  }, 0);
  return splitSentence.slice(0, lastValidWord).reduce((prev, current) => `${prev} ${current}`, '');
}

export function truncateIfLarger(str) {
  const MAX_STRING_LENGTH = 50;
  if (typeof str !== 'string') return '';
  if (str.length > MAX_STRING_LENGTH) return `${getSentenceToCompleteWord(str, MAX_STRING_LENGTH)}...`;
  return str;
}

export function fillArrayWithContent(size, content) {
  // eslint-disable-next-line no-plusplus
  const a = new Array(size); for (let i = 0; i < size; ++i) a[i] = content;
  return a;
}
