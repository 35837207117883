import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types';

function Alert(props) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <MuiAlert variant="standard" {...props} />;
}

export default function SnackbarAlert(props) {
  const {
    alert, handleClose,
  } = props;

  return (
    <div>
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={alert.open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={alert.alertSeverity}>
          {alert.alertMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

SnackbarAlert.propTypes = {
  alert: PropTypes.shape({
    alertMessage: PropTypes.string.isRequired,
    alertSeverity: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
  }).isRequired,
  handleClose: PropTypes.func.isRequired,
};
