/* eslint-disable class-methods-use-this */

import { MONTHS, Calculator } from '../../../pojo-helpers/pojo-helper';

class BaseTypeMap {
  constructor(keyName = null, label = {},
    values = {}, valuesBreakdown = {}) {
    if (typeof keyName !== 'string') throw new Error('Property keyName must be of type \'string\'');
    if (typeof label !== 'object') throw new Error('Property label must be of type \'object\'');
    if (typeof values !== 'object') throw new Error('Property values must be of type \'object\'');
    if (typeof valuesBreakdown !== 'object') throw new Error('Property valuesBreakdown must be of type \'object\'');

    this.keyName = keyName;
    this.label = label;
    this.values = values;
    this.valueBreakdown = valuesBreakdown;
  }

  display() {
    throw new Error('No display() provided in implementation');
  }

  // eslint-disable-next-line no-unused-vars
  calculator(values, valueBreakdown, data) {
    throw new Error('No calculator() provided in implementation');
  }

  weeklyBreakdown() {
    throw new Error('No weeklyBreakdown() provided in implementation');
  }

  quarterlyBreakdown() {
    throw new Error('No quarterlyBreakdown() provided in implementation');
  }

  monthlyBreakdown() {
    throw new Error('No monthlyBreakdown() provided in implementation');
  }

  annualBreakdown() {
    throw new Error('No annualBreakdown() provided in implementation');
  }

  projectionBreakdown(values, valueBreakdown, data) {
    return this.calculator(values, valueBreakdown, data);
  }

  projectionTotal(values, valueBreakdown, data = null) {
    const mathVerificationBreakdown = this.projectionBreakdown(values, valueBreakdown, data);
    return mathVerificationBreakdown.map((t) => t.numericAmount).reduce((a, b) => Calculator.convertToFloat(a) + Calculator.convertToFloat(b));
  }

  emptySet() {
    return MONTHS.map((month) => ({
      month,
      amount: Calculator.convertToCurrency(0),
      numericAmount: 0,
    }));
  }

  mergeSets(results, toMerge) {
    return results.map((num, index) => ({
      month: num.month,
      numericAmount: num.numericAmount + toMerge[index].numericAmount,
      amount: Calculator.convertToCurrency(num.numericAmount + toMerge[index].numericAmount),
    }));
  }

  get getValues() {
    return JSON.parse(JSON.stringify(this.values));
  }

  get getValueBreakdown() {
    return JSON.parse(JSON.stringify(this.valueBreakdown));
  }

  get getKey() {
    return this.keyName;
  }
}

export default BaseTypeMap;
