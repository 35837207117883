import React from 'react';
import PropTypes from 'prop-types';

const BudgetTitles = (props) => {
  const { title } = props;

  return (
    <div className="text-xl mb-2 ml-2">
      {title}
    </div>
  );
};

BudgetTitles.propTypes = {
  title: PropTypes.string.isRequired,
};
export default BudgetTitles;
