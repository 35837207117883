import React from 'react';
import {
  TextField,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  BreakdownEnum, convertDurationToFriendly, westlandTextField,
} from '../../../pojo-helpers/pojo-helper';
import { BudgetExtender, NumberFormatCustom } from '../../Helper/helper-index';
import MenuList from '../../Helper/MenuList/MenuList';

function PerItemContract(props) {
  const {
    handleSelectChange, handleNumberChange, labels, labelHelpers, setValues, setValueBreakdown, values, valueBreakdown,
  } = props;

  const classes = westlandTextField();
  return (
    <div className="mt-16 mb-16">
      {values.map((value, index) => (
        <div className="flex justify-center mb-10">
          <div className="flex flex-col">
            <div>
              <TextField
                className={`${classes.root} p-2 w-72`}
                label={labels[0]}
                value={value.numberFormat}
                onChange={(event) => handleNumberChange(event, index)}
                name="numberFormat"
                id="formatted-numberFormat-input"
                variant="outlined"
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
            </div>
            <div className="mt-12">
              <TextField
                type="number"
                className={`${classes.root} p-2 w-72`}
                label={labels[1].replace('SELECTED', convertDurationToFriendly(valueBreakdown[index].breakdown))}
                value={value.numberOf}
                onChange={(event) => handleNumberChange(event, index)}
                name="numberOf"
                id="formatted-amountFormat-input"
                variant="outlined"
              />
            </div>

          </div>
          <div className="mt-10 ml-4">
            <MenuList
              capitalize
              budgetType={valueBreakdown[index].breakdown}
              inputLabel={labels[2]}
              enumerationObject={BreakdownEnum}
              handleSelectChanges={(event) => handleSelectChange(event, index)}
              helperText={labelHelpers[0]}
              name="breakdown"
              value={valueBreakdown[index].breakdown}
            />
          </div>
        </div>
      ))}
      <BudgetExtender results={{ values, valueBreakdown }} setValues={setValues} setValueBreakdown={setValueBreakdown} />
    </div>
  );
}

PerItemContract.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  labelHelpers: PropTypes.arrayOf(PropTypes.string).isRequired,
  values: PropTypes.arrayOf(PropTypes.shape({
    numberFormat: PropTypes.string.isRequired,
    numberOf: PropTypes.string.isRequired,
  })).isRequired,
  valueBreakdown: PropTypes.arrayOf(PropTypes.shape({
    breakdown: PropTypes.string.isRequired,
  })).isRequired,
  handleSelectChange: PropTypes.func.isRequired,
  handleNumberChange: PropTypes.func.isRequired,
  setValues: PropTypes.func.isRequired,
  setValueBreakdown: PropTypes.func.isRequired,
};

export default PerItemContract;
