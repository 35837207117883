import React, { useEffect } from 'react';
import {
  Drawer, IconButton, List, ListItemIcon, ListItemText, ListItem, Divider, Avatar,
} from '@material-ui/core';
import {
  Menu, House, RateReview, TableChart,
} from '@material-ui/icons';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Steps } from 'intro.js-react';
import { westlandListStyles } from '../../../pojo-helpers/SharedStyles';
import SignOut from '../../LoginForm/SignOut/SignOut';
import { STRING_CONF, TutorialNames, TutorialSteps } from '../../../pojo-helpers/pojo-helper';
import PropertyDialogue from '../PropertyDialogue/PropertyDialogue';

function HamburgerDrawer(props) {
  const history = useHistory();
  const { open, toggleDrawer, user } = props;
  const [openDialogue, setOpenDialogue] = React.useState(false);
  const [userRole, setUserRole] = React.useState({});

  useEffect(async () => {
    user.getIdTokenResult().then((idTokenResult) => setUserRole(idTokenResult.claims));
  }, []);

  const glTable = () => {
    toggleDrawer(false);
    history.push('/');
  };

  const reviewProperties = () => {
    toggleDrawer(false);
    history.push('/reviewtable');
  };

  const classes = westlandListStyles();
  return (
    <div>
      <PropertyDialogue open={openDialogue} setOpen={setOpenDialogue} />
      <IconButton id="hamburger-menu" onClick={toggleDrawer} aria-label="delete">
        <Menu />
      </IconButton>
      <Drawer anchor="left" open={open} onClose={toggleDrawer}>
        <Steps
          enabled={localStorage.getItem(TutorialNames.HamburgerMenu) !== '0' && open}
          steps={TutorialSteps.hamburgerMenu}
          initialStep={TutorialSteps.initialStep}
          options={TutorialSteps.options}
          onExit={() => TutorialSteps.onExit(TutorialNames.HamburgerMenu)}
        />
        <List className={classes.list}>
          <ListItem>
            <Avatar alt="John Doe" src={user.photoURL} className={classes.large} />
            <ListItemText primary={`${user.displayName}`} secondary={user?.email} />
          </ListItem>
          <Divider />
          <SignOut />
          <ListItem button onClick={glTable}>
            <ListItemIcon><TableChart /></ListItemIcon>
            <ListItemText primary={STRING_CONF.USER_SELECT_GL} />
          </ListItem>
          <ListItem id="select-prop" button onClick={() => setOpenDialogue(true)}>
            <ListItemIcon><House /></ListItemIcon>
            <ListItemText primary={STRING_CONF.USER_SELECT_PROPERTIES} />
          </ListItem>
          { (userRole.admin || userRole.asset || userRole.regional) && (
            <ListItem button onClick={reviewProperties}>
              <ListItemIcon><RateReview /></ListItemIcon>
              <ListItemText primary={STRING_CONF.USER_SELECT_REVIEW} />
            </ListItem>
          )}

        </List>
      </Drawer>
    </div>
  );
}

HamburgerDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
  user: PropTypes.shape({
    displayName: PropTypes.string,
    photoURL: PropTypes.string,
    email: PropTypes.string,
    getIdTokenResult: PropTypes.func,
  }).isRequired,
};

export default HamburgerDrawer;
