/* eslint-disable class-methods-use-this */
import React from 'react';
import BaseTypeMap from '../BaseTypeMap/BaseTypeMap';
import MapHandler from '../MapHandler/MapHandler';
import { BreakdownEnum, BudgetTypeEnum, MONTHS } from '../../../pojo-helpers/Collections';
import { Calculator, STRING_CONF } from '../../../pojo-helpers/pojo-helper';
import { PerItemContract } from '../../BudgetTypes/budget-types-index';

// TODO Constrain 0 <= x
class PerItemContractTypeMap extends BaseTypeMap {
  constructor(...args) {
    super(...args);
  }

  calculator(values, valueBreakdown) {
    let results = this.emptySet();
    values.forEach((value, index) => {
      let singleEstimateValue;
      const { breakdown } = valueBreakdown[index];
      switch (breakdown) {
        case '':
          singleEstimateValue = this.emptySet();
          break;
        case BreakdownEnum.Weekly:
          singleEstimateValue = this.weeklyBreakdown(value);
          break;
        case BreakdownEnum.Quarterly:
          singleEstimateValue = this.quarterlyBreakdown(value);
          break;
        case BreakdownEnum.Monthly:
          singleEstimateValue = this.monthlyBreakdown(value);
          break;
        case BreakdownEnum.Annually:
          singleEstimateValue = this.annualBreakdown(value);
          break;
        default:
          throw new Error(`${JSON.stringify(breakdown)}: Unknown breakdown type`);
      }
      results = this.mergeSets(results, singleEstimateValue);
    });
    return results;
  }

  display(values, valueBreakdown, handleNumberChange, handleSelectChange, generalData, setValues, setValueBreakdown) {
    return (
      <PerItemContract
        labels={this.label.labels}
        labelHelpers={this.label.labelHelpers}
        values={values}
        valueBreakdown={valueBreakdown}
        handleNumberChange={handleNumberChange}
        handleSelectChange={handleSelectChange}
        setValues={setValues}
        setValueBreakdown={setValueBreakdown}
      />
    );
  }

  weeklyBreakdown(values) {
    const { numberFormat, numberOf } = values;
    const amount = numberOf * numberFormat;
    return MONTHS.map((month, index) => ({
      month,
      numericAmount: amount * Calculator.getNumWeeksForMonth(
        new Date().getFullYear(),
        index,
      ),
      amount:
        Calculator.convertToCurrency(
          amount * Calculator.getNumWeeksForMonth(
            new Date().getFullYear(),
            index,
          ),
        ),
    }));
  }

  quarterlyBreakdown(values) {
    const { numberFormat, numberOf } = values;
    const amount = numberOf * numberFormat;

    return MONTHS.map((month) => ({
      month,
      numericAmount: (amount * 4) / MONTHS.length,
      amount: (Calculator.convertToCurrency((amount * 4) / MONTHS.length)),
    }));
  }

  monthlyBreakdown(values) {
    const { numberFormat, numberOf } = values;
    const amount = numberOf * numberFormat;

    return MONTHS.map((month) => ({
      month,
      numericAmount: amount,
      amount: Calculator.convertToCurrency(amount),
    }));
  }

  annualBreakdown(values) {
    const { numberFormat, numberOf } = values;
    const amount = numberOf * numberFormat;

    const amountPerMonth = (Calculator.convertToFloat(amount) / MONTHS.length);
    return MONTHS.map((month) => ({
      month,
      numericAmount: amountPerMonth,
      amount:
        Calculator.convertToCurrency(amountPerMonth),
    }));
  }
}

MapHandler.register(
  BudgetTypeEnum.PerItemContract,
  new PerItemContractTypeMap(
    BudgetTypeEnum.PerItemContract,
    {
      labels: [STRING_CONF.PERITEM_LABEL_AMOUNT, STRING_CONF.PERITEM_LABEL_NUMBEROF, STRING_CONF.PERITEM_MENULIST_BREAKDOWN],
      labelHelpers: [STRING_CONF.PERITEM_MENULIST_BREAKDOWN_HELPER],
    },
    [{ numberFormat: '0.00', numberOf: '0' }],
    [{ breakdown: '' }],
  ),
);

export default PerItemContractTypeMap;
