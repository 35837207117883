import React from 'react';
import PropTypes from 'prop-types';
import { Button, Divider } from '@material-ui/core';
import uhOhImg from '../../../imgs/uh-oh-img.png';
import { westlandTextField } from '../../../pojo-helpers/pojo-helper';

function ErrorContainer(props) {
  const { errorMessage, retryAction } = props;

  const classes = westlandTextField();

  return (
    <div className="mt-20 w-full flex justify-center items-center flex-col">
      <div className="text-3xl mb-4">
        <span className="text-4xl italics font-bold">Uh-oh</span>
        , something went wrong
        <Divider />
      </div>
      <div className="flex justify-center items-center flex-col mb-4">
        <div className="text-sm text-gray-600 mb-2">
          Error Message:
          {' '}
          {errorMessage}
        </div>
        <div className="text-lg mb-1">
          It looks like our data failed to load properly.
        </div>
        <div>
          Would you like to give it another shot or would you like to report this issue?
        </div>
      </div>
      <div className="flex">
        <div>
          <Button type="button" className={classes.buttonBackground} variant="contained" color="secondary" onClick={retryAction}>
            Reload and retry
          </Button>
        </div>
        <div className="ml-12">
          <Button type="button" className={classes.buttonBackground} variant="contained" color="secondary" onClick={retryAction}>
            Report this issue
          </Button>
        </div>
      </div>
      <div className="mt-12 text-lg">In the mean time, we hope this makes you feel better</div>
      <img className="" src={uhOhImg} width="1000" height="1000" alt="A dog to make the error better" />

    </div>
  );
}

ErrorContainer.defaultProps = {
  errorMessage: 'Unknown',
};

ErrorContainer.propTypes = {
  errorMessage: PropTypes.string,
  retryAction: PropTypes.func.isRequired,
};

export default ErrorContainer;
