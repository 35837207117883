import React from 'react';
import PropTypes from 'prop-types';

function ReviewPageHeader(props) {
  const { glCode, subtitle, title } = props;
  return (
    <div className="flex flex-col">
      <span className="text-xl font-semibold">
        {glCode}
        {' '}
        -
        {' '}
        {title}
      </span>
      <span className="text-gray-500 text-sm mt-2">{subtitle}</span>
    </div>
  );
}

ReviewPageHeader.propTypes = {
  glCode: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default ReviewPageHeader;
