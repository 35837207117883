/* eslint-disable class-methods-use-this */
import React from 'react';
import {
  BreakdownEnum, Calculator, MONTHS, STRING_CONF,
} from '../../../pojo-helpers/pojo-helper';
import BaseTypeMap from '../BaseTypeMap/BaseTypeMap';
import MapHandler from '../MapHandler/MapHandler';
import { Seasonal } from '../../BudgetTypes/budget-types-index';

class SeasonalTypeMap extends BaseTypeMap {
  constructor(...args) {
    super(...args);
  }

  calculator(values, valueBreakdown) {
    let results = this.emptySet();
    values.forEach((value, index) => {
      let singleSeasonalValue;
      const { breakdown } = valueBreakdown[index];
      switch (breakdown) {
        case '':
          singleSeasonalValue = this.emptySet();
          break;
        case BreakdownEnum.Weekly:
          singleSeasonalValue = this.weeklyBreakdown(value, valueBreakdown[index]);
          break;
        case BreakdownEnum.Monthly:
          singleSeasonalValue = this.monthlyBreakdown(value, valueBreakdown[index]);
          break;
        case BreakdownEnum.Annually:
          singleSeasonalValue = this.annualBreakdown(value, valueBreakdown[index]);
          break;
        default:
          throw new Error(`${JSON.stringify(breakdown)}: Unknown breakdown type`);
      }
      results = this.mergeSets(results, singleSeasonalValue);
    });
    return results;
  }

  display(values, valueBreakdown, handleNumberChange, handleSelectChange) {
    return (
      <Seasonal
        labels={this.label.labels}
        labelHelpers={this.label.labelHelpers}
        values={values}
        valueBreakdown={valueBreakdown}
        handleSelectChange={handleSelectChange}
        handleNumberChange={handleNumberChange}
      />
    );
  }

  weeklyBreakdown(values, valueBreakdown) {
    const { numberFormat } = values;
    const { firstMonth, lastMonth } = valueBreakdown;

    return MONTHS.map((month, index) => {
      const numWeeks = Calculator.getNumWeeksForMonth(new Date().getFullYear(), index);
      return {
        month,
        numericAmount: (index >= firstMonth && index <= lastMonth)
          ? numberFormat * numWeeks
          : 0,
        amount: (index >= firstMonth && index <= lastMonth)
          ? Calculator.convertToCurrency(
            Calculator.convertToFloat(numberFormat) * numWeeks,
          )
          : '$0',
      };
    });
  }

  monthlyBreakdown(values, valueBreakdown) {
    const { numberFormat } = values;
    const { firstMonth, lastMonth } = valueBreakdown;

    const calculatedAmount = Calculator.convertToFloat(numberFormat);

    return MONTHS.map((month, index) => ({
      month,
      numericAmount: (index >= firstMonth && index <= lastMonth)
        ? calculatedAmount
        : 0,
      amount: (index >= firstMonth && index <= lastMonth)
        ? Calculator.convertToCurrency(calculatedAmount)
        : Calculator.convertToCurrency(0),
    }));
  }

  annualBreakdown(values, valueBreakdown) {
    const { numberFormat } = values;
    const { firstMonth, lastMonth } = valueBreakdown;

    const numberOfMonths = lastMonth - firstMonth + 1;
    const calculatedAmount = Calculator.convertToFloat(numberFormat);

    return MONTHS.map((month, index) => ({
      month,
      numericAmount: (index >= firstMonth && index <= lastMonth)
        ? calculatedAmount / numberOfMonths
        : 0,
      amount: (index >= firstMonth && index <= lastMonth)
        ? Calculator.convertToCurrency(calculatedAmount / numberOfMonths)
        : Calculator.convertToCurrency(0),
    }));
  }
}

MapHandler.register('seasonal',
  new SeasonalTypeMap(
    'seasonal',
    {
      labels: [STRING_CONF.SEASONAL_LABEL_AMOUNT, STRING_CONF.SEASONAL_MENULIST_BREAKDOWN, STRING_CONF.SEASONAL_MENULIST_FIRSTMONTH, STRING_CONF.SEASONAL_MENULIST_LASTMONTH],
      labelHelpers: [STRING_CONF.SEASONAL_MENULIST_BREAKDOWN_HELPER, STRING_CONF.SEASONAL_MENULIST_FIRSTMONTH_HELPER, STRING_CONF.SEASONAL_MENULIST_LASTMONTH_HELPER],
    },
    [{
      numberFormat: '0.00',
    }],
    [{
      breakdown: '',
      firstMonth: 0,
      lastMonth: 0,
    }],
  ));

export default SeasonalTypeMap;
