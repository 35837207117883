import React, { useEffect } from 'react';
import { Button, Divider } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { onAuthStateChanged } from '@firebase/auth';
import { ConfirmationDialogue } from '../../Helper/helper-index';
import {
  auth, axiosPut, getActiveProperty, getRegionalDetails, STRING_CONF, westlandTextField,
} from '../../../pojo-helpers/pojo-helper';
import reviewHandler from '../../../pojo-helpers/ReviewHandler';
import { CompletionStatus } from '../../../pojo-helpers/Collections';

function BudgetFooter(props) {
  const {
    glInformation, isComplete, parentGl, open, setOpen, notes, raiseAlert, updated, url, values, valueBreakdown,
  } = props;

  const [user, setUser] = React.useState(null);
  const [disabled, setDisabled] = React.useState(false);
  const history = useHistory();
  const { historicalData } = useSelector((state) => state.historicals);

  const isValidEntry = (inputValue) => inputValue.every(
    (value) => ((typeof value === 'object')
      ? Object.values(value).every((nestedValue) => nestedValue !== undefined && nestedValue !== '')
      : value !== undefined && value !== ''),
  );

  onAuthStateChanged(auth, (authUser) => { setUser(authUser); });

  useEffect(() => {
    // Consider array cases.
    try {
      const arrayedValues = Object.values(values);
      const arrayedBreakdown = Object.values(valueBreakdown);

      const valueValid = isValidEntry(arrayedValues);
      const breakdownValid = arrayedBreakdown.length <= 0 || isValidEntry(arrayedBreakdown);
      setDisabled(!(valueValid && breakdownValid));
    } catch (e) {
      console.log(e);
    }
  }, [values, valueBreakdown]);

  const handleExit = () => {
    history.push('/');
  };
  const handleDialogue = (event) => {
    if (event === 'openExit' && !updated) handleExit();
    setOpen({
      ...open,
      [event]: !open[event],
    });
  };

  const handleExitSave = (completed) => () => {
    const putRequest = axiosPut({
      budgetData: {
        values,
        valueBreakdown,
      },
      isComplete: completed,
      managerApproval: user.email,
      notes,
    }, url);
    putRequest.then(async (res) => {
      if (res?.status === 200) {
        const { toAddress, regionalName } = await getRegionalDetails();
        const emailPayload = {
          toAddress,
          managerName: user.displayName,
          parentGl,
          propertyCode: await getActiveProperty(),
          regionalName,
        };
        reviewHandler(parentGl, historicalData, glInformation.glCodeRaw, glInformation.turns, glInformation.totalUnits, user, emailPayload);
        history.push('/');
      } else {
        raiseAlert('Saving this request has failed. Please try again', 'error');
      }
    });
  };
  const classes = westlandTextField();
  return (
    <div>
      <div className="mt-4 flex flex-row-reverse justify-between">
        <div className="flex">
          <Button id="button-exit" onClick={() => handleDialogue('openExit')} className={classes.buttonText}>{STRING_CONF.FOOTER_EXIT}</Button>
          <Divider orientation="vertical" flexItem />
          <Button id="button-incomplete" disabled={disabled} onClick={((isComplete) ? () => (handleDialogue('openComplete')) : handleExitSave(CompletionStatus.Incomplete))} className={classes.buttonText}>
            {STRING_CONF.FOOTER_SAVE_INCOMPLETE}
          </Button>
          <Divider orientation="vertical" flexItem />
          <Button id="button-complete" disabled={disabled} onClick={handleExitSave(CompletionStatus.Complete)} className={classes.buttonText}>{STRING_CONF.FOOTER_SAVE_COMPLETE}</Button>
        </div>
      </div>
      <ConfirmationDialogue
        dialogueTitle={STRING_CONF.FOOTER_DIALOGUE_EXIT_TITLE}
        dialogueContext={STRING_CONF.FOOTER_DIALOGUE_EXIT_CONTEXT}
        dialogueOptionLeft={STRING_CONF.FOOTER_DIALOGUE_EXIT_OPTION_LEFT}
        dialogueOptionRight={STRING_CONF.FOOTER_DIALOGUE_EXIT_OPTION_RIGHT}
        open={open.openExit}
        handleClose={() => handleDialogue('openExit')}
        successResult={handleExit}
      />
      <ConfirmationDialogue
        dialogueTitle={STRING_CONF.FOOTER_DIALOGUE_INCOMPLETE_TITLE}
        dialogueContext={STRING_CONF.FOOTER_DIALOGUE_INCOMPLETE_CONTEXT}
        dialogueOptionLeft={STRING_CONF.FOOTER_DIALOGUE_INCOMPLETE_OPTION_LEFT}
        dialogueOptionRight={STRING_CONF.FOOTER_DIALOGUE_INCOMPLETE_OPTION_RIGHT}
        open={open.openComplete}
        handleClose={() => handleDialogue('openComplete')}
        successResult={handleExitSave(CompletionStatus.Incomplete)}
      />
    </div>
  );
}

BudgetFooter.defaultProps = {
  valueBreakdown: {},
  notes: '',
};

BudgetFooter.propTypes = {
  isComplete: PropTypes.bool.isRequired,
  parentGl: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  notes: PropTypes.string,
  raiseAlert: PropTypes.func.isRequired,
  glInformation: PropTypes.shape({
    glCodeRaw: PropTypes.string.isRequired,
    turns: PropTypes.arrayOf(PropTypes.number).isRequired,
    totalUnits: PropTypes.number.isRequired,
  }).isRequired,
  updated: PropTypes.bool.isRequired,
  url: PropTypes.string.isRequired,
  values: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape({
    numberFormat: PropTypes.string,
    percentFormat: PropTypes.string,
    numberOf: PropTypes.string,
    0: PropTypes.string,
    1: PropTypes.string,
    2: PropTypes.string,
    3: PropTypes.string,
    4: PropTypes.string,
    5: PropTypes.string,
    6: PropTypes.string,
    7: PropTypes.string,
    8: PropTypes.string,
    9: PropTypes.string,
    10: PropTypes.string,
    11: PropTypes.string,
  })), PropTypes.shape({})]).isRequired,
  valueBreakdown: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape()), PropTypes.shape({})]),

};

export default BudgetFooter;
