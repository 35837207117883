import { makeStyles } from '@material-ui/core';

export const westlandTextField = makeStyles({
  root: {
    '& label': {
      color: '#CF3F36',
    },
    '& label.Mui-focused': {
      color: '#CF3F36',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#616161',
      },
      '&:hover fieldset': {
        borderColor: '#616161',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#CF3F36',
      },
    },
  },
  breakdown: {
    marginLeft: 24,
  },
  buttonText: {
    color: '#CF3F36',
  },
  successText: {
    color: '#4BB543',
  },
  notFoundText: {
    color: '#CF3F36',
    fontSize: '1.875rem',
    lineHeight: '2.25rem',
  },
  buttonBackground: {
    backgroundColor: '#CF3F36',
    '&:hover': {
      backgroundColor: '#fefefe',
      color: '#CF3F36',
    },
  },
}, { index: 1 });

export const westlandReviewTable = makeStyles({
  root: {
    '& label': {
      color: '#CF3F36',
    },
    '& label.Mui-focused': {
      color: '#CF3F36',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#616161',
      },
      '&:hover fieldset': {
        borderColor: '#616161',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#CF3F36',
      },
    },
  },
}, { index: 1 });

export const westlandDataTable = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
    borderBottom: 'unset',
  },
  subRow: {
    '& > *': {
      borderBottom: 'unset',
    },
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.07)',
    },
    borderBottom: 'unset',
    transition: 'all .23s ease-in-out',
  },
  highlightRow: {
    backgroundColor: 'rgba(0, 0, 0, 0.07)',
  },
}, { index: 1 });

export const westlandMathVerificationText = makeStyles({
  root: {
    '& label': {
      color: '#CF3F36',
    },
    '& input': {
      backgroundColor: '#f4f4f4',
    },
    '& label.Mui-focused': {
      color: '#CF3F36',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#616161',
      },
      '&:hover fieldset': {
        borderColor: '#616161',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#616161',
        borderWidth: 1,
      },
    },
  },
}, { index: 1 });

export const westlandListStyles = makeStyles({
  list: {
    width: 325,
  },
  large: {
    marginRight: 12,
    width: 50,
    height: 50,
  },
  textColor: {
    color: '#CF3F36',
  },
}, { index: 1 });
