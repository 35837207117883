const STRING_CONF = {
  ALERT_ERROR: 'error',
  ALERT_WARNING: 'warning',
  ALERT_MESSAGE_MONTH_FIRST: 'The first month was set later than the last month. As a result, the last month was adjusted.',
  ALERT_MESSAGE_MONTH_LAST: 'The last month can\'t be set earlier than the first month',

  DEV_EMAIL: 'jacob.mc@westlandreg.com',
  DEV_MODE_WARNING: 'DEV BUILD: Progress is NOT saved',

  ERROR_NO_USER: 'There is no detected user logged in',
  ERROR_NO_ASSOCIATED_PROPERTIES: 'The logged in user does not have properties associated. Contact your Regional',
  ERROR_NO_ACTIVE_PROPERTY: 'The logged in user does not have an active property selected',
  ERROR_NO_PROPERTIES: 'The logged in user does not have a properties list',
  ERROR_CAN_NOT_CALCULATE: 'Unable to calculate the subrow value: ',

  ESTIMATE_LABEL_ESTIMATED_SPENDING: 'Total SELECTED Estimated Spend',
  ESTIMATE_MENULIST_BREAKDOWN: 'Breakdown',
  ESTIMATE_MENULIST_BREAKDOWN_HELPER: 'Determines the method of breakdown',

  FOOTER_SAVE_COMPLETE: 'SAVE AS COMPLETE',
  FOOTER_SAVE_INCOMPLETE: 'SAVE AS INCOMPLETE',
  FOOTER_EXIT: 'EXIT WITHOUT SAVING',
  FOOTER_SET_BUDGET: 'SET BUDGET TO $0 FOR THIS YEAR',
  FOOTER_DIALOGUE_EXIT_TITLE: 'Exit without saving?',
  FOOTER_DIALOGUE_EXIT_CONTEXT: "You haven't saved your changes, are you sure you'd like to continue?",
  FOOTER_DIALOGUE_EXIT_OPTION_LEFT: 'Stay on this page',
  FOOTER_DIALOGUE_EXIT_OPTION_RIGHT: 'Leave without saving',
  FOOTER_DIALOGUE_INCOMPLETE_TITLE: 'Mark budget as incomplete?',
  FOOTER_DIALOGUE_INCOMPLETE_CONTEXT: "This budget has already been marked as completed, are you sure you'd like to mark it as incomplete?",
  FOOTER_DIALOGUE_INCOMPLETE_OPTION_LEFT: 'Stay on this page',
  FOOTER_DIALOGUE_INCOMPLETE_OPTION_RIGHT: 'Mark as incomplete',

  HISTORICAL_METRIC_ANNUAL: 'Last year annual cost for this GL',
  HISTORICAL_METRIC_MONTHLY: 'Avg. Monthly Cost for this GL',
  HISTORICAL_METRIC_UNIT: 'Your total annual cost per unit spend',

  INTERVAL_LABEL_AMOUNT: 'Amount',
  INTERVAL_MENULIST_BREAKDOWN: 'Breakdown',
  INTERVAL_MENULIST_BREAKDOWN_HELPER: 'Determines the method of breakdown',
  INTERVAL_MENULIST_INTERVALSTART: 'Interval Start',
  INTERVAL_MENULIST_INTERVALSTART_HELPER: 'Determines the starting month of the interval cycle',

  LOADING_MESSAGES: [
    'Loading up the budgets...', 'Finding the missing paperwork...', 'Assembling the Avengers..!', 'Putting the Hamster on the Wheel...',
    'Gaining Sentience...', 'Eating all the Data...', 'There really is no wait, I just want you to read these...',
    'There\'s a snake in my boot..!', 'Getting the IT Team to turn the Computer on...', 'Will this be on the test..?', 'Downloading more RAM...', 'Come here often..?',
    '♫ Elevator Music ♫', 'May I offer you an egg, in this trying time..?', 'Engaging Hyperdrive...', 'How\'s my hair look..?',
    '01001100 01101111 01100001 01100100 01101001 01101110 01100111', 'Establishing Skynet...', 'At least you\'re not on hold...', 'Insert two tokens to play...',
    'Sorting budgets into Wizarding houses...', 'No, I am your Father...', 'I\'ve been trying to reach you about your car\'s extended warranty...',
  ],

  LOGIN_TITLE: 'WESTLAND BUDGETS',
  LOGIN_SUBTITLE: 'In order to sign in, you must log in with a valid Westland email address. You must also have an Asset Manager approved account.',

  MATH_COLUMN_TITLE: 'Monthly Breakdown',
  MANUAL_OVERRIDE_CHECKBOX: 'Enable Manual Adjustment?',

  PERCENT_LABEL_INCREASE: 'Percent increase over historical ',

  PERITEM_LABEL_AMOUNT: 'Amount',
  PERITEM_LABEL_NUMBEROF: 'Item Amount',
  PERITEM_MENULIST_BREAKDOWN: 'Breakdown',
  PERITEM_MENULIST_BREAKDOWN_HELPER: 'Determines the method of breakdown',

  PERITEMVAR_LABEL_AMOUNT: 'Amount per SELECTED',
  PERITEMVAR_LABEL_PERCENT: 'Percentage',
  PERITEMVAR_MENULIST_BREAKDOWN: 'Breakdown',
  PERITEMVAR_MENULIST_BREAKDOWN_HELPER: 'Determines the method of breakdown',

  PLACE_HOLDER: 'PLACE_HOLDER_REPLACE',

  PROJECTION_METRIC_ANNUAL: 'Last year annual cost compared to next year',
  PROJECTION_METRIC_MONTHLY: 'Avg. Monthly Cost compared to next year',
  PROJECTION_METRIC_UNIT: 'Cost per unit spend compared to next year',

  SEASONAL_LABEL_AMOUNT: 'Amount',
  SEASONAL_MENULIST_BREAKDOWN: 'Breakdown',
  SEASONAL_MENULIST_BREAKDOWN_HELPER: 'Determines the method of breakdown',
  SEASONAL_MENULIST_FIRSTMONTH: 'First Month',
  SEASONAL_MENULIST_FIRSTMONTH_HELPER: 'Select the starting month',
  SEASONAL_MENULIST_LASTMONTH: 'Last Month',
  SEASONAL_MENULIST_LASTMONTH_HELPER: 'Select the ending month',

  SESSION_STORAGE_FILTER: 'currentFilter',
  SESSION_LAST_CLICKED: 'lastClicked',

  USER_SELECT_GL: 'GL Table',
  USER_SELECT_REVIEW: 'Review Properties',
  USER_SELECT_PROPERTIES: 'Select Property',
  USER_LOGOUT_TEXT: 'Sign out',
  USER_PROPERTIES_DIALOGUE_TITLE: 'Select the Property you want to view',

  TOOLTIP_HISTORICAL_ANNUAL: 'The Historical annual cost of this GL for your property, compared against last year\'s spending',
  TOOLTIP_HISTORICAL_MONTHLY: 'The Historical monthly cost of this GL for your property, compared against last year\'s spending',
  TOOLTIP_HISTORICAL_UNIT: 'The Historical Annual cost, broken down by your property\'s units, compared against last year\'s spending',

  TOOLTIP_PROJECTIONS_ANNUAL: 'The annual Projections of this GL for your property, compared against last year\'s spending',
  TOOLTIP_PROJECTIONS_MONTHLY: 'The monthly Projections of this GL for your property, compared against last year\'s spending',
  TOOLTIP_PROJECTIONS_UNIT: 'The annual Projection cost, broken down by your property\'s units, compared against last year\'s spending',

  TOOLTIP_PROJECTED_REVENUE: 'Projected annual revenue based on numbers input by regional and asset managers',
  TOOLTIP_PROJECTED_EXPENSES: 'Total projected expenses of numbers you have so far input into the system',
  TOOLTIP_PROJECTED_UNIT_SPEND: 'Total projected expenses of numbers you have so far input into the system divided by your total units',
  TOOLTIP_PROJECTED_NOI: 'Total projected revenue minus total projected expenses',
  TOOLTIP_COMPLETION_RATE: 'The percentage of completed GLs',
};

export default STRING_CONF;
