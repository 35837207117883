import React, { useEffect } from 'react';
import {
  Box,
  Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, MenuItem, Select,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  getActiveProperty, getProperties, setActiveProperty, STRING_CONF,
} from '../../../pojo-helpers/pojo-helper';

async function getUserProperties(setOptions) {
  const properties = await getProperties();
  if (properties) setOptions(properties);
}

function PropertyDialogue(props) {
  const { open, setOpen } = props;
  const [value, setValue] = React.useState(null);
  const [options, setOptions] = React.useState([]);
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const setProperty = async () => {
    if (value) {
      setOpen(false);
      await setActiveProperty(value);
      window.location.reload(false);
    }
  };

  useEffect(() => {
    getUserProperties(setOptions || []);
    getActiveProperty().then((res) => ((res !== '-1') ? setValue(res) : setValue(null)));
  }, []);

  return (
    <Dialog
      disableEscapeKeyDown
      open={open}
    >
      <DialogTitle>{STRING_CONF.USER_PROPERTIES_DIALOGUE_TITLE}</DialogTitle>
      <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap' }}>
        <DialogContent className="mb-2">
          <FormControl
            fullWidth
          >
            <Select
              labelId="properties-dialog-select-label"
              id="properties-dialog-select"
              label="Properties"
              value={value}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              {options.map((option) => (
                <MenuItem
                  value={option}
                  key={option}
                >
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
      </Box>
      <DialogActions>
        <Button autoFocus onClick={() => setOpen(false)}>
          Cancel
        </Button>
        <Button onClick={setProperty}>Select</Button>
      </DialogActions>
    </Dialog>
  );
}

PropertyDialogue.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default PropertyDialogue;
