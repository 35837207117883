/* eslint-disable no-unused-vars */
import { useHistory } from 'react-router-dom';
import React, { useEffect } from 'react';
import {
  Box, Collapse, IconButton, Table, TableBody, TableCell, TableHead, TableRow,
} from '@material-ui/core';
import {
  CheckCircle, KeyboardArrowDown, KeyboardArrowUp, Warning,
} from '@material-ui/icons';
import { green, yellow } from '@material-ui/core/colors';
import PropTypes from 'prop-types';
import HashMap from 'hashmap';
import { Steps } from 'intro.js-react';
import {
  Calculator, COLUMNS, MONTHS, STRING_CONF, truncateIfLarger, TutorialSteps, westlandDataTable,
} from '../../../pojo-helpers/pojo-helper';
import { gradientCategory } from '../../../pojo-helpers/GenericHelpers';
import { CompletionStatus } from '../../../pojo-helpers/Collections';
import { BudgetStatus } from '../../Helper/helper-index';

function filterCriteria(filters, row, searchValue) {
  if (searchValue) {
    // If the parent is undefined, it is a parent.
    if (!searchValue?.parentGl && searchValue?.glCode === row?.parent?.glCode) return true;
    // If you have a specific search value. I.E the RHS search option AND you are in the right bucket, return true (Since you need to see the category
    if (searchValue?.parentGl?.glCode === row?.parent?.glCode) return true;
    // If you are in the wrong bucket and the searchValue is enabled, no further searching is required.
    if (searchValue?.parentGl !== row.parent.glCode) return false;
  }

  // There isn't a filter, so everything is good.
  if (filters.length <= 0) return true;

  // Run through the .every return below. Filters out bucket fields
  const shouldBeFiltered = (filter) => {
    const { operator } = filter;
    switch (operator) {
      case '==':
        return row.children.some((child) => filter.requirement === child[filter.filterType]);
      default:
        return true;
    }
  };

  return filters.every(shouldBeFiltered);
}

// Runs multiple times, maybe restrict it? You can verify by putting a log in and filtering.
function isFilteredChild(child, searchValue, open, handleToggle) {
  // If search is defined and it matches the glCode, then we have our filtered child!
  const isResult = (searchValue && searchValue?.glReference?.glCode === child?.glCode);
  if (isResult && !open) handleToggle();
  return (searchValue && searchValue?.glReference?.glCode === child?.glCode);
}

function Row(props) {
  const history = useHistory();
  const {
    row, historicals, filters, propertyData, searchValue, uniqueID,
  } = props;
  const lastClicked = React.useState(sessionStorage.getItem(STRING_CONF.SESSION_LAST_CLICKED));

  const [open, setOpen] = React.useState(sessionStorage.getItem(uniqueID) === 'true' || false);
  // const budgetInfo = MapHandler.get(row.budgetType);
  const handleToggle = () => {
    const newState = !open;
    setOpen(newState);
    sessionStorage.setItem(uniqueID, (newState) ? 'true' : 'false');
  };
  const classes = westlandDataTable();
  const filter = filterCriteria(filters, row, searchValue);

  const handleNestedClick = (bucket, glItem) => {
    sessionStorage.setItem(STRING_CONF.SESSION_LAST_CLICKED, uniqueID);
    history.push({
      pathname: '/budgetform',
      state: {
        budgetDataType: glItem.glReference.budgetDataType,
        description: glItem.glReference.description,
        glCode: glItem.glReference.glCode,
        historicals: bucket.get(glItem.glReference.glCode),
        isComplete: glItem.isComplete === CompletionStatus.Complete,
        notesData: glItem.notes,
        parentCategory: glItem.glReference.parentGl.description,
        parentGl: glItem.glReference.parentGl.glCode,
        propertyData,
        // eslint-disable-next-line no-underscore-dangle
        url: (process.env.NODE_ENV === 'production') ? (glItem._links.self.href).replace('http', 'https') : glItem._links.self.href,
        valuesData: glItem.budgetData.values,
        valueBreakdownData: glItem.budgetData.valueBreakdown,
      },
    });
  };

  useEffect(() => {
    // Filter is inverted because true will always render. In essence, we want to see if a filter has been set I.E False
    if (!filter) setOpen(false);

    if (lastClicked.length === 2 && lastClicked[0]) {
      try {
        document.getElementById(lastClicked[0])
          .scrollIntoView({ behavior: 'smooth' });
      } catch { console.log(''); }
    }
  }, []);

  const bucket = historicals.get(row.parent.glCode);
  let totalHistorical = 0.0;
  let totalProjections = 0.0;
  if (bucket) {
    totalHistorical = bucket.values().reduce((partialAmount, a) => partialAmount
      + a.reduce((monthAmount, b) => monthAmount + b, 0),
    0);

    totalProjections = row.children.reduce((partialAmount, glItem) => partialAmount
      + Calculator.calculateSubRowIfPresent(glItem, { historicals: bucket.get(glItem.glReference.glCode), turns: propertyData.turns, totalUnits: propertyData.units }).months.reduce((monthAmount, month) => monthAmount + month.numericAmount, 0),
    0);
  }

  return (
    <>
      {filter && row.parent
      && (
        <TableRow id={uniqueID} className={classes.root}>
          <TableCell style={{ minWidth: 40, maxWidth: 40 }}>
            <IconButton className="westlandButtonColor" aria-label="expand row" size="small" onClick={handleToggle}>
              {open ? <KeyboardArrowUp id="expand-row" /> : <KeyboardArrowDown id="expand-row" />}
            </IconButton>
          </TableCell>
          <TableCell id="budget-categories" component="th" scope="row" align="center" className="expandedRow" style={{ minWidth: 40, maxWidth: 40, fontWeight: open ? 'bold' : 'normal' }}>
            <BudgetStatus onClick={handleToggle} glData={row.children.map((child) => child.isComplete)} />
            {row.parent?.glCode}
          </TableCell>
          {/* Description */ }
          <TableCell className="expandedRow" align="left">{row.parent?.description}</TableCell>
          {/* Notes Intentionally blank */ }
          <TableCell className="expandedRow" align="left" />
          {/* PriorYear */ }
          <TableCell className="expandedRow" align="right">{Calculator.convertToCurrency(totalHistorical)}</TableCell>
          {/* CurrentYearTotal */ }
          <TableCell className="expandedRow" align="right">{Calculator.calculateBucketIfPresent(row, bucket, { turns: propertyData.turns, totalUnits: propertyData.units }).total}</TableCell>
          {/* Variance */ }
          <TableCell
            className="expandedRow"
            style={{
              color: `${gradientCategory(
                Calculator.calculatePercent(totalHistorical, totalProjections),
                row.children.every((child) => child.isComplete === CompletionStatus.Complete || child.isComplete === CompletionStatus.Approved),
              )}`,
            }}
            align="right"
          >
            {(Calculator.calculatePercent(totalHistorical, totalProjections) * 100).toFixed(2)}
            %
          </TableCell>
          {/* TODO Summation of subGLs  */ }
          {Calculator.calculateBucketIfPresent(row, bucket, { turns: propertyData.turns, totalUnits: propertyData.units }).months.map((month, index) => (
            <TableCell className="expandedRow" align="right" key={row.parent?.glCode + MONTHS[index]}>{month}</TableCell>
          ))}
        </TableRow>
      )}

      <TableRow className={!filter ? classes.root : ''}>
        <TableCell
          style={{
            paddingLeft: 0, paddingRight: 0, paddingBottom: 0, paddingTop: 0, minWidth: 100,
          }}
          // Plus one as we to include the "expand" arrow column
          colSpan={COLUMNS.length + 1}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={0}>
              <Table aria-label="purchases">
                <TableHead className="expandedHeader">
                  <TableRow className="expandedHeader">
                    <TableCell style={{ minWidth: 40, maxWidth: 40 }} className="expandedHeader" />
                    {COLUMNS.map((column) => (
                      <TableCell
                        className="expandedHeader"
                        key={`${column.name}-cell`}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          maxWidth: column.minWidth,
                        }}
                      />
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* Using the filter from the filter, verify that every requirement is passed before mapping the children */}
                  {(row.children).filter((child) => filters.every((filterInfo) => filterInfo.requirement === child.isComplete)).map((glItem) => (
                    <TableRow id="subGLCode" key={glItem.glReference.glCode} className={`${classes.subRow} ${(isFilteredChild(searchValue, glItem, open, handleToggle)) ? `${classes.highlightRow} animation-pulse-custom` : ''}`} onClick={() => handleNestedClick(bucket, glItem)}>
                      <TableCell />
                      <TableCell className="subRows" component="th" scope="row" align="center">
                        <BudgetStatus glData={glItem.isComplete} size="small" />
                        {glItem.glReference.glCode}
                      </TableCell>
                      {/* Description */ }
                      <TableCell className="subRows" align="left">{glItem.glReference.description}</TableCell>
                      {/* Notes */ }
                      <TableCell className="subRows" align="left">{truncateIfLarger(glItem.notes)}</TableCell>
                      {/* Actuals */ }
                      <TableCell className="subRows" align="right">
                        {

                        bucket
                        && Calculator.convertToCurrency(
                          bucket.get(glItem.glReference.glCode).reduce((partialSum, a) => partialSum + a, 0),
                        )
                        }
                      </TableCell>
                      {/* Projections */ }
                      <TableCell className="subRows" align="right">{Calculator.calculateSubRowIfPresent(glItem, { historicals: bucket.get(glItem.glReference.glCode), turns: propertyData.turns, totalUnits: propertyData.units }).stringTotal}</TableCell>
                      {/* Variance */ }
                      <TableCell className="subRows" align="right">
                        {Calculator.calculateVariance(glItem, bucket, { turns: propertyData.turns, totalUnits: propertyData.units })}
                        %
                      </TableCell>
                      {/* Months */}
                      {Calculator.calculateSubRowIfPresent(glItem, { historicals: bucket.get(glItem.glReference.glCode), turns: propertyData.turns, totalUnits: propertyData.units }).months.map((value) => (
                        <TableCell className="subRows" align="right" key={glItem.glReference.glCode + value.month}>{value.amount}</TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

Row.defaultProps = {
  searchValue: null,
};

Row.propTypes = {
  searchValue: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  filters: PropTypes.arrayOf(PropTypes.object).isRequired,
  propertyData: PropTypes.shape({
    units: PropTypes.number,
    turns: PropTypes.arrayOf(PropTypes.number),
  }).isRequired,
  row: PropTypes.shape({
    parent: PropTypes.shape({
      glCode: PropTypes.string,
      isComplete: PropTypes.number,
      propertyCode: PropTypes.string,
      description: PropTypes.string,
    }),
    children: PropTypes.arrayOf(PropTypes.shape({
      budgetType: PropTypes.string,
      budgetData: PropTypes.shape({}),
      glCode: PropTypes.string,
      isComplete: PropTypes.number,
      propertyCode: PropTypes.string,
      description: PropTypes.string,
    })),
  }).isRequired,
  uniqueID: PropTypes.string.isRequired,
  historicals: PropTypes.objectOf(HashMap).isRequired,
};

export default Row;
