/* eslint-disable no-underscore-dangle */
import React, { useEffect } from 'react';
import { ArrowBackRounded } from '@material-ui/icons';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Paper, Table, TableCell, TableContainer, TableHead, TableRow, TableBody, Checkbox, Tooltip, Fade,
} from '@mui/material';
import { useSelector } from 'react-redux';
import {
  axiosGet, BudgetTypeEnum, Calculator, EMPTY_PROPERTY_DATA, REVIEW_PAGE_COLUMNS, truncateIfLarger,
} from '../../../pojo-helpers/pojo-helper';
import { CenteredLoader } from '../../Helper/helper-index';
import ErrorContainer from '../../ErrorHandling/ErrorContainer/ErrorContainer';
import { MathVerificationColumn } from '../../BudgetForm';
import MapHandler from '../../JsonTypeMapper/MapHandler/MapHandler';
import { getPropertyData } from '../../../pojo-helpers/AxiosCalls';
import ReviewPageHeader from '../ReviewPageHeader/ReviewPageHeader';
import { gradientCategory } from '../../../pojo-helpers/GenericHelpers';
import ReviewFooter from '../ReviewFooter/ReviewFooter';

function createHistorical(historicals, propertyData) {
  return {
    historicals,
    totalUnits: propertyData.units,
    totalTurns: propertyData.turns.reduce((prev, curr) => prev + curr),
    turns: propertyData.turns,
  };
}

function ReviewPage() {
  const history = useHistory();
  const location = useLocation();
  const { historicalData } = useSelector((state) => {
    if (!state?.historicals?.areHistoricalsAvailable) history.push('/');
    return state.historicals;
  });
  if (!location?.state || historicalData.length <= 0) history.push('/');
  const { parentGl, property } = location?.state;
  const bucket = historicalData.get(parentGl);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);
  const [selected, setSelected] = React.useState([]);
  const [budgetData, setBudgetData] = React.useState([]);
  const [mathProjections, setMathProjects] = React.useState(Calculator.emptySet());
  const [propertyData, setPropertyData] = React.useState(EMPTY_PROPERTY_DATA);
  const isSelected = (url) => selected.some((selection) => selection.url === url);
  const handleClick = (event, url, glCode) => {
    let newSelected = [...selected];
    if (selected.some((selection) => selection.url === url)) newSelected = newSelected.filter((selection) => selection.url !== url);
    else newSelected.push({ glCode, url });
    setSelected(newSelected);
  };

  useEffect(async () => {
    if (parentGl) {
      await getPropertyData(property, setPropertyData);
      await axiosGet(setLoading, setError, setBudgetData, {
        'glReference.parentGl.glCode': parentGl,
        propertyCode: property,
      });
    } else {
      history.push('/reviewtable');
    }
  }, []);

  let totalHistorical = 0.0;
  let totalProjections = 0.0;
  if (bucket) {
    totalHistorical = bucket.values().reduce((partialAmount, a) => partialAmount
      + a.reduce((monthAmount, b) => monthAmount + b, 0),
    0);

    totalProjections = budgetData.reduce((partialAmount, glItem) => partialAmount
      + Calculator.calculateSubRowIfPresent(glItem, { historicals: bucket.get(glItem.glReference.glCode), turns: propertyData.turns, totalUnits: propertyData.units }).months.reduce((monthAmount, month) => monthAmount + month.numericAmount, 0),
    0);
  }

  return (
    <div>
      <ArrowBackRounded
        className="container-corner mb-5"
        style={{ fontSize: '35px' }}
        onClick={() => history.push('/reviewTable')}
      />
      <div>
        {loading && <CenteredLoader />}
        {/* TODO : Implement */}
        {error && !loading && <ErrorContainer retryAction={() => console.log('IMPLEMENT')} />}
        {!error && !loading && (
          <div>
            <div className="flex">
              <div className="review-notes">
                <ReviewPageHeader glCode={parentGl} title={budgetData[0]?.glReference?.parentGl?.description || 'None'} subtitle={property} />
                <div className="flex flex-col">
                  <TableContainer sx={{ minHeight: 400 }} className="mt-4" component={Paper}>
                    <Table aria-label="spanning table">
                      <TableHead>
                        {REVIEW_PAGE_COLUMNS.map((column) => (
                          <TableCell
                            key={column.name}
                            align={column.align}
                            style={{ maxWidth: column.minWidth, backgroundColor: 'rgb(229, 231, 235)' }}
                          >
                            <span className="text-lg">{column.name}</span>
                          </TableCell>
                        ))}
                      </TableHead>
                      <TableBody>
                        {budgetData.sort((a, b) => parseInt(a.glReference.glCode, 10) - parseInt(b.glReference.glCode, 10)).map((row) => {
                          const budgetInfo = MapHandler.get(BudgetTypeEnum[row.glReference.budgetDataType]);
                          const projectionArray = budgetInfo.projectionBreakdown(row?.budgetData?.values, row?.budgetData?.valueBreakdown, createHistorical(bucket.get(row?.glReference?.glCode), propertyData));
                          const isItemSelected = isSelected(row._links.self.href);
                          return (
                            <TableRow key={row.glReference.glCode} onClick={() => setMathProjects(projectionArray)}>
                              <TableCell padding="checkbox">
                                <Checkbox
                                  onClick={(event) => handleClick(event, row._links.self.href, row.glReference.glCode)}
                                  color="primary"
                                  checked={isItemSelected}
                                  inputProps={{
                                    'aria-labelledby': row.name,
                                  }}
                                />
                              </TableCell>
                              {/* Gl Code */}
                              <TableCell align="left">{row.glReference.glCode}</TableCell>
                              {/* Description */}
                              <TableCell align="left">{row.glReference.description}</TableCell>
                              {/* Actuals */}
                              <TableCell align="right">
                                {bucket && Calculator.convertToCurrency(
                                  bucket.get(row.glReference.glCode).reduce((partialSum, a) => partialSum + a, 0),
                                )}
                              </TableCell>
                              {/* Projections */}
                              <TableCell align="right">{Calculator.calculateSubRowIfPresent(row, { historicals: bucket.get(row.glReference.glCode), turns: propertyData.turns, totalUnits: propertyData.units }).stringTotal}</TableCell>
                              {/* Variance */}
                              <TableCell
                                align="right"
                                style={{
                                  color: `${gradientCategory(Calculator.calculatePercent(
                                    bucket.get(row.glReference.glCode).reduce((partialSum, a) => partialSum + a, 0),
                                    Calculator.calculateSubRowIfPresent(row, { historicals: bucket.get(row.glReference.glCode), turns: propertyData.turns, totalUnits: propertyData.units }).months.reduce((partialVal, a) => partialVal + a.numericAmount, 0),
                                  ))}`,
                                }}
                              >
                                {Calculator.calculateVariance(row, bucket, { turns: propertyData.turns, totalUnits: propertyData.units })}
                              </TableCell>
                              {/* Notes */}
                              <Tooltip
                                followCursor
                                TransitionComponent={Fade}
                                TransitionProps={{ timeout: 600 }}
                                title={row.notes}
                              >
                                <TableCell align="left">{truncateIfLarger(row.notes)}</TableCell>
                              </Tooltip>
                            </TableRow>
                          );
                        })}
                        <TableRow>
                          <TableCell />
                          <TableCell />
                          <TableCell colSpan={2} align="right">
                            <span className="font-bold text-lg mr-12">Total:</span>
                            <span className="font-bold text-lg">{Calculator.convertToCurrency(totalHistorical)}</span>
                          </TableCell>
                          <TableCell align="right"><span className="font-bold text-lg">{Calculator.convertToCurrency(totalProjections)}</span></TableCell>
                          <TableCell align="right">
                            <span
                              style={{
                                color: `${gradientCategory(Calculator.calculatePercent(totalHistorical, totalProjections))}`,
                              }}
                              className="font-bold text-lg"
                            >
                              {Calculator.convertToCurrency(totalProjections - totalHistorical)}
                            </span>
                          </TableCell>
                          <TableCell />
                        </TableRow>

                      </TableBody>
                    </Table>
                  </TableContainer>
                  <ReviewFooter allGlUrls={budgetData.map((glItem) => glItem._links.self.href)} allGlCodes={budgetData.map((glItem) => glItem.glReference.glCode)} parentGl={parentGl} property={property} selectionData={selected} />
                </div>
              </div>
              <div className="review-math flex justify-center ml-6">
                <MathVerificationColumn projectionTotals={mathProjections} />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ReviewPage;
