/* eslint-disable class-methods-use-this */
import React from 'react';
import BaseTypeMap from '../BaseTypeMap/BaseTypeMap';
import MapHandler from '../MapHandler/MapHandler';
import {
  BudgetTypeEnum, Calculator, IntervalEnum, MONTHS, STRING_CONF,
} from '../../../pojo-helpers/pojo-helper';
import { Interval } from '../../BudgetTypes/budget-types-index';

class IntervalTypeMap extends BaseTypeMap {
  constructor(...args) {
    super(...args);
  }

  calculator(values, valueBreakdown) {
    let results = this.emptySet();
    values.forEach((value, index) => {
      const { numberFormat } = value;
      const { breakdown, intervalStart } = valueBreakdown[index];
      const interval = this.getIntervalLength(breakdown);
      const startingPoint = intervalStart;

      const validMonths = [];
      for (let i = startingPoint; i < 12; i += interval) {
        validMonths.push(i);
      }

      const singleIntervalValue = MONTHS.map((month, monthIndex) => ({
        month,
        amount: (validMonths.includes(monthIndex))
          ? Calculator.convertToCurrency(numberFormat)
          : Calculator.convertToCurrency(0),
        numericAmount: (validMonths.includes(monthIndex))
          ? Calculator.convertToFloat(numberFormat)
          : 0,
      }));
      results = this.mergeSets(results, singleIntervalValue);
    });
    return results;
  }

  display(values, valueBreakdown, handleNumberChange, handleSelectChange) {
    return (
      <Interval
        values={values}
        valueBreakdown={valueBreakdown}
        handleNumberChange={handleNumberChange}
        handleSelectChange={handleSelectChange}
        labels={this.label.labels}
        labelHelpers={this.label.labelHelpers}
      />
    );
  }

  getIntervalLength(intervalStart) {
    switch (intervalStart) {
      case '':
        return 1;
      case IntervalEnum['Every Other']:
        return 2;
      case IntervalEnum.Quarterly:
        return 3;
      case IntervalEnum.Quadrimesterly:
        return 4;
      case IntervalEnum.Biannually:
        return 6;
      default:
        throw new Error(`${intervalStart} unexpected breakdownType`);
    }
  }
}

MapHandler.register(
  BudgetTypeEnum.Interval,
  new IntervalTypeMap(
    BudgetTypeEnum.Interval,
    {
      labels: [STRING_CONF.INTERVAL_LABEL_AMOUNT, STRING_CONF.INTERVAL_MENULIST_BREAKDOWN, STRING_CONF.INTERVAL_MENULIST_INTERVALSTART],
      labelHelpers: [STRING_CONF.INTERVAL_MENULIST_BREAKDOWN_HELPER, STRING_CONF.INTERVAL_MENULIST_INTERVALSTART_HELPER],
    },
    [{ numberFormat: '0.00' }],
    [{ breakdown: '', intervalStart: 0 }],
  ),
);

export default IntervalTypeMap;
