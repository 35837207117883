import React, { useEffect } from 'react';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import { onAuthStateChanged } from '@firebase/auth';
import { useHistory } from 'react-router-dom';
import { TextArea } from '../../BudgetForm';
import { auth } from '../../../pojo-helpers/firebase';
import {
  getManagerEmailByProperty,
  getUserData, pushReviewData, sendEmail, setGlReviewStatus,
} from '../../../pojo-helpers/AxiosCalls';
import { CompletionStatus } from '../../../pojo-helpers/Collections';

async function createPayload(approved, glCode, notes, property, user) {
  const userInfo = await getUserData(user.email);
  const { role } = userInfo;
  if (approved === CompletionStatus.Rejected) {
    return {
      glCode,
      property,
      isReady: false,
    };
  }
  if (userInfo) {
    switch (role) {
      case 'Asset':
        return {
          assetNotes: notes,
          assetApproval: user.email,
          glCode,
          isReady: false,
          needsAsset: false,
          property,
        };
      case 'Regional':
        return {
          glCode,
          needsRegional: false,
          property,
          regionalApproval: user.email,
          regionalNotes: notes,
        };
      default:
        throw new Error('Unaccepted User');
    }
  }
  // Todo handle better
  throw new Error('Undefined user');
}

async function createEmailPayload(glCodes, propertyCode, regional, regionalNotes) {
  return {
    glCodes,
    propertyCode,
    regionalName: regional.displayName,
    regionalNotes,
    toAddress: await getManagerEmailByProperty(propertyCode),
  };
}

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function ReviewFooter(props) {
  const history = useHistory();
  const [notes, setNotes] = React.useState('');
  const [user, setUser] = React.useState(null);
  const {
    allGlUrls, parentGl, property, selectionData,
  } = props;

  useEffect(() => {
    onAuthStateChanged(auth, (authUser) => { setUser(authUser); });
  }, []);

  const handleSubmission = async (approvedStatus) => {
    const MERGE_DATA = true;
    const selectUrls = selectionData.map((selection) => ((process.env.NODE_ENV === 'production') ? (selection.url).replace('http://', 'https://') : selection.url));
    const allGlUrlsCleaned = allGlUrls.map((glUrl) => ((process.env.NODE_ENV === 'production') ? (glUrl).replace('http://', 'https://') : glUrl));
    const selectGls = selectionData.map((selection) => selection.glCode);
    const payload = await createPayload(approvedStatus, parentGl, notes, property, user);
    if (approvedStatus === CompletionStatus.Rejected) {
      const emailPayload = await createEmailPayload(selectGls, property, user, notes);
      const selectedFields = (selectUrls.length <= 0) ? allGlUrlsCleaned : selectUrls;
      setGlReviewStatus(selectedFields, CompletionStatus.Rejected);
      sendEmail(emailPayload, 'sendManagerEmail');
    } else {
      setGlReviewStatus(allGlUrlsCleaned, CompletionStatus.Approved);
    }
    await pushReviewData(payload, MERGE_DATA);
    history.push('/reviewtable');
  };

  return (
    <div className="flex mt-8 ml-2 mr-2">
      <div className="review-table">
        <TextArea notes={notes} setNotes={setNotes} />
      </div>
      <div className="flex flex-col justify-center items-center review-buttons">
        {/* Rejection/Failure State */}
        <Button color="error" onClick={() => handleSubmission(CompletionStatus.Rejected)} type="button" variant="contained"> Send back to Manager </Button>
        {/* Approval/Success State */}
        <div className="mt-2"><Button color="success" onClick={() => handleSubmission(CompletionStatus.Approved)} type="button" variant="contained"> Approve this Category </Button></div>
      </div>
    </div>
  );
}

ReviewFooter.propTypes = {
  allGlUrls: PropTypes.arrayOf(PropTypes.string).isRequired,
  parentGl: PropTypes.string.isRequired,
  property: PropTypes.number.isRequired,
  selectionData: PropTypes.arrayOf(PropTypes.shape({
    glCode: PropTypes.arrayOf(PropTypes.string).isRequired,
    url: PropTypes.arrayOf(PropTypes.string).isRequired,
  })).isRequired,
};

export default ReviewFooter;
