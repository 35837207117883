import React from 'react';
import PropTypes from 'prop-types';
import { MetricView } from '../../Helper/helper-index';
import { Calculator, STRING_CONF } from '../../../pojo-helpers/pojo-helper';

function GLFormHeader(props) {
  const {
    historicalTotal, projectedExpenses, percentCompleteDecimal, totalUnits,
  } = props;
  const amountBudgeted = projectedExpenses.reduce((partialAmount, a) => partialAmount + Calculator.calculateBucketIfPresent(a).numericTotal, 0);
  const percentCompleteDisplay = `${(percentCompleteDecimal * 100).toFixed(2)}%`;
  const metricData = [{
    id: 'Generic-1',
    average: {
      number: Calculator.convertToCurrency(amountBudgeted - historicalTotal),
      averageBlurb: ' from last year',
      classResult: 'text-red-500',
    },
    blurb: 'Projected Expenses',
    total: Calculator.convertToCurrency(amountBudgeted),
    tooltip: STRING_CONF.TOOLTIP_PROJECTED_EXPENSES,
  }, {
    id: 'Generic-2',
    average: {
      number: Calculator.convertToCurrency((amountBudgeted / totalUnits) - (historicalTotal / totalUnits)),
      averageBlurb: ' from last year',
      classResult: 'text-red-500',
    },
    blurb: 'Per Unit Spend',
    total: Calculator.convertToCurrency(amountBudgeted / totalUnits),
    tooltip: STRING_CONF.TOOLTIP_PROJECTED_UNIT_SPEND,
  }, {
    id: 'Generic-3',
    average: {
      number: '',
      averageBlurb: 'of budgets done',
      classResult: 'text-red-500',
    },
    blurb: 'Completion Rate',
    total: percentCompleteDisplay,
    tooltip: STRING_CONF.TOOLTIP_COMPLETION_RATE,
  },
  ];
  const metricItems = metricData.map((metricItem, index) => (
    <div data-testid={`${metricItem.id}-test`} id={`card-panel-${index}`} key={metricItem.id} className="flex-initial">
      <MetricView metricData={metricItem} />
    </div>
  ));

  return (
    <div data-testid="GLFormHeader-test">
      <div className="flex justify-around mb-12">
        {metricItems}
      </div>
    </div>
  );
}

GLFormHeader.propTypes = {
  historicalTotal: PropTypes.number.isRequired,
  percentCompleteDecimal: PropTypes.number.isRequired,
  projectedExpenses: PropTypes.arrayOf(PropTypes.shape({
    parent: PropTypes.shape({}),
    children: PropTypes.arrayOf(PropTypes.shape({})),
  })).isRequired,
  totalUnits: PropTypes.number.isRequired,
};

export default GLFormHeader;
