import React from 'react';
import LoginDisplay from '../LoginDisplay/LoginDisplay';

function LoginForm() {
  return (
    <div className="h-screen">
      <div className="h-full flex flex-col justify-center items-center">
        <LoginDisplay />
      </div>
    </div>
  );
}

export default LoginForm;
