import React from 'react';
import { TextField } from '@material-ui/core';
import PropTypes, { string } from 'prop-types';
import {
  BreakdownEnum, BudgetTypeEnum, capitalizeFirstLetter, westlandTextField,
} from '../../../pojo-helpers/pojo-helper';
import {
  BudgetExtender,
  NumberFormatCustom,
} from '../../Helper/helper-index';

import MenuList from '../../Helper/MenuList/MenuList';

function Estimates(props) {
  const {
    handleSelectChange, handleNumberChange, labels, labelHelpers, setValues, setValueBreakdown, values, valueBreakdown,
  } = props;
  const classes = westlandTextField();
  return (
    <div className="mt-16 mb-16">
      <div className="flex justify-center">
        <div className="flex flex-col justify-around ">
          {values.map((value, index) => {
            const valBreak = valueBreakdown[index];
            return (
              // eslint-disable-next-line react/no-array-index-key
              <div key={index}>
                <TextField
                  className={`${classes.root} p-2 w-64`}
                  label={labels[0].replace('SELECTED', capitalizeFirstLetter(valBreak.breakdown))}
                  value={value.numberFormat}
                  onChange={(event) => handleNumberChange(event, index)}
                  name="numberFormat"
                  id="formatted-numberFormat-input"
                  variant="outlined"
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                />
                <MenuList
                  budgetType={BudgetTypeEnum.Estimate}
                  capitalize
                  inputLabel={labels[1]}
                  enumerationObject={BreakdownEnum}
                  handleSelectChanges={(event) => handleSelectChange(event, index)}
                  helperText={labelHelpers[0]}
                  name="breakdown"
                  value={valBreak.breakdown}
                />
              </div>
            );
          })}
        </div>
      </div>
      <BudgetExtender results={{ values, valueBreakdown }} setValues={setValues} setValueBreakdown={setValueBreakdown} />
    </div>
  );
}

Estimates.propTypes = {
  labels: PropTypes.arrayOf(string).isRequired,
  labelHelpers: PropTypes.arrayOf(string).isRequired,
  values: PropTypes.arrayOf(PropTypes.shape({
    numberFormat: PropTypes.string.isRequired,
  })).isRequired,
  valueBreakdown: PropTypes.arrayOf(PropTypes.shape({
    breakdown: PropTypes.string.isRequired,
  })).isRequired,
  handleSelectChange: PropTypes.func.isRequired,
  handleNumberChange: PropTypes.func.isRequired,
  setValues: PropTypes.func.isRequired,
  setValueBreakdown: PropTypes.func.isRequired,
};

export default Estimates;
