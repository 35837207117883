import {
  BudgetTypeEnum, CompletionStatus,
  MONTHS,
} from './Collections';
import MapHandler from '../components/JsonTypeMapper/MapHandler/MapHandler';

export default class Calculator {
  static getPercentComplete(budgetDataEntryArray) {
    const filteredArray = budgetDataEntryArray.filter((bde) => bde.parentGl !== '');
    const total = filteredArray.length;
    const completed = filteredArray.filter((bde) => bde.isComplete === CompletionStatus.Complete || bde.isComplete === CompletionStatus.Approved).length;
    return (completed / total);
  }

  static convertToFloat(amount) {
    if (typeof amount === 'string') {
      return parseFloat(amount) || 0;
    } if (typeof amount === 'number') {
      return amount;
    }
    throw new Error(`Unexpected typeof ${amount}. Expected [string,number]`);
  }

  static convertToCurrency(amount) {
    const valueToConvert = Calculator.convertToFloat(amount);
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return formatter.format(valueToConvert);
  }

  static convertToCurrencyArray(amountArray) {
    const valueToConvert = amountArray.map((amount) => Calculator.convertToFloat(amount));
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });
    return valueToConvert.map((valueAmount) => formatter.format(valueAmount));
  }

  static getNumWeeksForMonth(year, month) {
    const date = new Date(year, month);
    const calculatedMonth = date.getMonth();
    let mondays = 0;

    date.setDate(1);

    // Get the first Monday in the month
    while (date.getDay() !== 1) {
      date.setDate(date.getDate() + 1);
    }

    // Get all the other Mondays in the month
    while (date.getMonth() === calculatedMonth) {
      mondays += 1;
      date.setDate(date.getDate() + 7);
    }

    return mondays;
  }

  static emptySet() {
    return MONTHS.map((month) => ({
      month,
      amount: Calculator.convertToCurrency(0),
      numericAmount: 0,
    }));
  }

  static calculateSubRowIfPresent(glItem, historicals) {
    const budgetInfo = MapHandler.get(BudgetTypeEnum[glItem.glReference.budgetDataType]);
    // Since the fields may not always be defined we will return a basic array when data is not present.

    try {
      const months = budgetInfo.calculator(glItem.budgetData.values, glItem.budgetData.valueBreakdown, historicals);
      const total = months.reduce((accum, month) => accum + month.numericAmount, 0.0);
      return { stringTotal: Calculator.convertToCurrency(total), total, months };
    } catch (e) {
      return {
        stringTotal: '$0.00',
        total: 0.0,
        months: MONTHS.map((month) => ({
          month,
          numericAmount: 0,
          amount: '$0.00',
        })),
      };
    }
  }

  static calculateBucketIfPresent(row, bucket, propertyData = {}) {
    const subRowValues = row.children.map((glItem) => (Calculator.calculateSubRowIfPresent(glItem, { historicals: bucket?.get(glItem.glReference.glCode), ...propertyData })));
    const headerRowData = {
      total: 0.0,
      numericTotal: 0.0,
      months: new Array(12).fill(0.0),
      breakdownMonths: MONTHS.map((month) => ({
        month,
        numericAmount: 0,
        amount: '$0.00',
      })),
    };
    subRowValues.forEach((entry) => {
      headerRowData.total += entry.total;
      const subRowMonth = entry.months.map((month) => month.numericAmount);
      headerRowData.months = headerRowData.months.map((val, index) => val + subRowMonth[index]);
      headerRowData.breakdownMonths = headerRowData.breakdownMonths.map((val, index) => ({
        month: val.month,
        numericAmount: val.numericAmount + subRowMonth[index],
        amount: Calculator.convertToCurrency(val.numericAmount + subRowMonth[index]),
      }));
    });
    headerRowData.numericTotal = headerRowData.total;
    headerRowData.total = Calculator.convertToCurrency(headerRowData.total);
    headerRowData.months = headerRowData.months.map((month) => Calculator.convertToCurrency(month));
    return headerRowData;
  }

  static calculateVariance(glItem, bucket, propertyData) {
    if (!bucket) return '$0.00';
    const actual = Calculator.calculateSubRowIfPresent(glItem, { historicals: bucket.get(glItem.glReference.glCode), ...propertyData }).months.reduce((partialVal, a) => partialVal + a.numericAmount, 0);
    const projection = bucket.get(glItem.glReference.glCode).reduce((partialSum, a) => partialSum + a, 0);
    return (Calculator.calculatePercent(projection, actual) * 100).toFixed(2);
  }

  static calculatePercent(original, updatedValue) {
    if (Number.isNaN(original) || Number.isNaN(updatedValue)) throw new Error('?');
    if (original <= 0 || updatedValue <= 0) return 0;
    return ((updatedValue - original) / original);
  }
}
