import React from 'react';
import { IconButton } from '@material-ui/core';
import { ControlPoint, RemoveCircleOutline } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { westlandTextField } from '../../../pojo-helpers/SharedStyles';

function BudgetExtender(props) {
  const classes = westlandTextField();
  const { results, setValues, setValueBreakdown } = props;

  const updateValues = (value, breakdown) => {
    setValues([...value]);
    setValueBreakdown([...breakdown]);
  };

  const extendValues = () => {
    const value = results.values;
    const breakdown = results.valueBreakdown;
    value.push({ ...value[value.length - 1] });
    breakdown.push({ ...breakdown[breakdown.length - 1] });
    updateValues(value, breakdown);
  };

  const removeValues = () => {
    const value = results.values;
    const breakdown = results.valueBreakdown;
    if (value.length <= 1) return;

    value.pop();
    breakdown.pop();
    updateValues(value, breakdown);
  };

  return (
    <div className="flex justify-center">
      <IconButton onClick={removeValues} className={classes.buttonText} aria-label="Add Field">
        <RemoveCircleOutline />
      </IconButton>
      <IconButton onClick={extendValues} className={classes.successText} aria-label="Add Field">
        <ControlPoint />
      </IconButton>
    </div>
  );
}

BudgetExtender.propTypes = {
  results: PropTypes.shape({
    values: PropTypes.arrayOf(PropTypes.shape({})),
    valueBreakdown: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  setValues: PropTypes.func.isRequired,
  setValueBreakdown: PropTypes.func.isRequired,
};

export default BudgetExtender;
