import MapHandler from '../MapHandler/MapHandler';
import EstimatesBaseTypeMap from '../EstimatesBaseTypeMap/EstimatesBaseTypeMap';
import { BudgetTypeEnum, STRING_CONF } from '../../../pojo-helpers/pojo-helper';

class FlatContractTypeMap extends EstimatesBaseTypeMap {
  constructor(...args) {
    super(...args);
  }
}

MapHandler.register(BudgetTypeEnum.FlatContract, new FlatContractTypeMap(
  BudgetTypeEnum.FlatContract,
  {
    labels: [STRING_CONF.ESTIMATE_LABEL_ESTIMATED_SPENDING, STRING_CONF.ESTIMATE_MENULIST_BREAKDOWN],
    labelHelpers: [STRING_CONF.ESTIMATE_MENULIST_BREAKDOWN_HELPER],
  },
  [{ numberFormat: '0.00' }],
  [{ breakdown: '' }],
));

export default FlatContractTypeMap;
