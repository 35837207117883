import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Steps } from 'intro.js-react';
import DataTable from '../DataTable/DataTable';
import GLFormHeader from '../GLFormHeader/GLFormHeader';
import {
  Calculator, TutorialNames, TutorialSteps,
} from '../../../pojo-helpers/pojo-helper';
import DataHandler from '../../../pojo-helpers/DataHandler';
import { setHistorical } from '../../../redux/historical';

export default function GLForm(props) {
  // Assumption of receiving the proper BudgetDataEntry
  const { data, historicals, propertyData } = props;
  const dispatch = useDispatch();

  // This is here to allow us to calculate the completion percent
  const percentCompleteDecimal = Calculator.getPercentComplete(data);
  const res = DataHandler.returnBuckets(data);
  const historicalRes = DataHandler.returnBucketsHistorical(historicals, data);

  dispatch(setHistorical(historicalRes));

  return (
    <>
      {/* This is here to prevent error logging for when it first initializes with no values look at onMount */}
      { res.length > 1
        && (
        <div>
          <Steps
            enabled={localStorage.getItem(TutorialNames.GlTutorial) !== '0'}
            steps={TutorialSteps.glForm}
            initialStep={TutorialSteps.initialStep}
            options={TutorialSteps.options}
            onExit={() => TutorialSteps.onExit(TutorialNames.GlTutorial)}
          />
          <GLFormHeader
            historicalTotal={historicals.reduce((prev, curr) => prev + curr.amount, 0) || 0}
            projectedExpenses={res}
            percentCompleteDecimal={percentCompleteDecimal}
            totalUnits={propertyData.units}
          />
          <DataTable dataTableEntries={res} dataTableHistoricals={historicalRes} propertyData={propertyData} />
        </div>
        )}
    </>
  );
}

GLForm.propTypes = {
  data: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.shape({}).isRequired,
    PropTypes.shape({
      glCode: PropTypes.string.isRequired,
      propertyCode: PropTypes.string.isRequired,
      budgetData: PropTypes.string.isRequired,
      isComplete: PropTypes.bool.isRequired,
    }).isRequired,
  ])).isRequired,
  historicals: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.shape({}).isRequired,
    PropTypes.shape({
      glCode: PropTypes.string.isRequired,
      propertyCode: PropTypes.string.isRequired,
      postMonth: PropTypes.string.isRequired,
      amount: PropTypes.number.isRequired,
      parentGL: PropTypes.string.isRequired,
    }).isRequired,
  ])).isRequired,
  propertyData: PropTypes.shape({
    units: PropTypes.number,
    turns: PropTypes.arrayOf(PropTypes.number),
  }).isRequired,
};
