import {
  Checkbox, FormControlLabel, FormGroup, IconButton, Menu, TextField, Toolbar, Tooltip,
} from '@material-ui/core';
import { FilterList } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';
import PropTypes from 'prop-types';

function EnhancedTableToolbar(props) {
  const {
    handleClick, anchorEl, handleChange, handleClose, handleSearchChange, filterStatus, searchRows, searchValue,
  } = props;
  const searchRowsCloned = JSON.parse(JSON.stringify(searchRows));
  const searchArray = [];
  // The child glReference is the desired field, we could've passed the item independently, but I opted for just spreading and morphing.
  searchRowsCloned.forEach((entries) => searchArray.push(entries.parent, ...(entries.children.map((child) => child.glReference))));
  searchArray.filter((obj) => obj);
  return (
    <div data-testid="DataTable-test">
      <Toolbar className="glFilterColor flex justify-between">
        <Tooltip title="Filter" arrow>
          <IconButton id="filter-menu-icon" className="westlandButtonColor" aria-controls="simple-menu" aria-label="filter list" aria-haspopup="true" onClick={handleClick}>
            <FilterList />
          </IconButton>
        </Tooltip>
        <Autocomplete
          value={searchValue}
          onChange={handleSearchChange}
          className="searchBox"
          id="filter-bar"
          options={searchArray.sort((a, b) => parseInt(b?.glCode, 10) - parseInt(a?.glCode, 10))}
          getOptionLabel={(row) => `${row?.glCode} - ${row?.searchName}`}
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          renderInput={(params) => <TextField {...params} label="GL Codes" />}
        />
      </Toolbar>

      <Menu
        id="filter-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <FormGroup className="p-2 pl-4">
          <FormControlLabel
            control={<Checkbox checked={filterStatus.filterApproved} onChange={handleChange} name="filterApproved" />}
            label="Approved"
          />
          <FormControlLabel
            control={<Checkbox checked={filterStatus.filterComplete} onChange={handleChange} name="filterComplete" />}
            label="Complete"
          />
          <FormControlLabel
            control={<Checkbox checked={filterStatus.filterIncomplete} onChange={handleChange} name="filterIncomplete" />}
            label="Incomplete"
          />
          <FormControlLabel
            control={<Checkbox checked={filterStatus.filterRejected} onChange={handleChange} name="filterRejected" />}
            label="Rejected"
          />
        </FormGroup>
      </Menu>
    </div>
  );
}

EnhancedTableToolbar.defaultProps = {
  searchValue: 'null',
};

EnhancedTableToolbar.propTypes = {
  searchValue: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  searchRows: PropTypes.array.isRequired,
  handleClick: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types,react/require-default-props
  anchorEl: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  handleSearchChange: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  filterStatus: PropTypes.shape({
    filterApproved: PropTypes.bool.isRequired,
    filterComplete: PropTypes.bool.isRequired,
    filterIncomplete: PropTypes.bool.isRequired,
    filterRejected: PropTypes.bool.isRequired,
  }).isRequired,
};

export default EnhancedTableToolbar;
