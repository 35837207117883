import React from 'react';
// eslint-disable-next-line no-unused-vars
import PropTypes from 'prop-types';
import {
  InputAdornment, TextField,
} from '@material-ui/core';
import {
  capitalizeFirstLetter,
  PerItemVariableTypes,
  westlandTextField,
} from '../../../pojo-helpers/pojo-helper';
import {
  BudgetExtender,
  NumberFormatCustom,
} from '../../Helper/helper-index';

import MenuList from '../../Helper/MenuList/MenuList';

function PerItemVariable(props) {
  const {
    handleNumberChange, handleSelectChange, labels, labelHelpers, setValues, setValueBreakdown, values, valueBreakdown,
  } = props;
  const classes = westlandTextField();
  return (
    <div className="mt-16 mb-16">
      {values.map((value, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div className="flex justify-center mb-10" key={index}>
          <div className="flex flex-col">
            <div>
              <TextField
                className={`${classes.root} p-2 w-64`}
                label={labels[0].replace('SELECTED', capitalizeFirstLetter(valueBreakdown[index].itemVariable))}
                value={value.numberFormat}
                onChange={(event) => handleNumberChange(event, index)}
                name="numberFormat"
                id="formatted-numberFormat-input"
                variant="outlined"
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
            </div>
            <div className="mt-12">
              <TextField
                type="number"
                className={`${classes.root} p-2 w-64`}
                label={labels[1]}
                value={value.percentFormat}
                onChange={(event) => handleNumberChange(event, index)}
                name="percentFormat"
                id="formatted-numberFormat-input"
                variant="outlined"
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
              />
            </div>
          </div>
          <div className="mt-10 ml-4">
            <MenuList
              capitalize
              helperText={labelHelpers[0]}
              value={valueBreakdown[index].itemVariable}
              name="itemVariable"
              inputLabel={labels[2]}
              enumerationObject={PerItemVariableTypes}
              budgetType="PerItemVariable"
              handleSelectChanges={(event) => handleSelectChange(event, index)}
            />
          </div>
        </div>
      ))}
      <BudgetExtender results={{ values, valueBreakdown }} setValues={setValues} setValueBreakdown={setValueBreakdown} />
    </div>
  );
}

PerItemVariable.propTypes = {
  handleNumberChange: PropTypes.func.isRequired,
  handleSelectChange: PropTypes.func.isRequired,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  labelHelpers: PropTypes.arrayOf(PropTypes.string).isRequired,
  values: PropTypes.arrayOf(PropTypes.shape({
    percentFormat: PropTypes.string.isRequired,
    numberFormat: PropTypes.string.isRequired,
  })).isRequired,
  valueBreakdown: PropTypes.arrayOf(PropTypes.shape({
    itemVariable: PropTypes.string.isRequired,
  })).isRequired,
  setValues: PropTypes.func.isRequired,
  setValueBreakdown: PropTypes.func.isRequired,
};

export default PerItemVariable;
