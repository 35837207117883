/* eslint-disable class-methods-use-this */
import React from 'react';
import BaseTypeMap from '../BaseTypeMap/BaseTypeMap';
import MapHandler from '../MapHandler/MapHandler';
import {
  BudgetTypeEnum, Calculator, MONTHS, STRING_CONF,
} from '../../../pojo-helpers/pojo-helper';
import { PercentIncrease } from '../../BudgetTypes/budget-types-index';

// TODO Constrain on 0 <= x <= 100
class PercentIncreaseTypeMap extends BaseTypeMap {
  constructor(...args) {
    super(...args);
  }

  calculator(values, _, data) {
    const { percentFormat } = values;
    const historicalData = data.historicals;
    const percent = (percentFormat) ? (percentFormat / 100.0) : 0.0;
    if (!historicalData) throw new Error("Can't computer percent increase without historical data");
    return MONTHS.map((month, index) => {
      const historicalAmount = historicalData.filter(
        (___, historicalIndex) => index === historicalIndex,
      )[0];

      const amountCalculated = (values[index] && values[index] > 0)
        ? values[index]
        : historicalAmount + (historicalAmount * percent);
      return {
        month,
        numericAmount: Calculator.convertToFloat(amountCalculated),
        amount: Calculator.convertToCurrency(amountCalculated),
      };
    });
  }

  display(values, valueBreakdown, handleNumberChange, _, generalData) {
    const { historicals } = generalData;
    return (
      <div>
        <PercentIncrease
          handleNumberChange={handleNumberChange}
          historicals={historicals}
          label={this.label[0]}
          values={values}
        />
      </div>

    );
  }
}

MapHandler.register(
  BudgetTypeEnum.FlatPercentIncrease,
  new PercentIncreaseTypeMap(
    BudgetTypeEnum.FlatPercentIncrease,
    [STRING_CONF.PERCENT_LABEL_INCREASE],
    {
      percentFormat: '0.00',
      0: '0.00',
      1: '0.00',
      2: '0.00',
      3: '0.00',
      4: '0.00',
      5: '0.00',
      6: '0.00',
      7: '0.00',
      8: '0.00',
      9: '0.00',
      10: '0.00',
      11: '0.00',
    },
  ),
);

export default PercentIncreaseTypeMap;
