import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { onAuthStateChanged } from '@firebase/auth';
import { useSelector } from 'react-redux';
import HamburgerDrawer from '../../User/HamburgerDrawer/HamburgerDrawer';
import { auth } from '../../../pojo-helpers/firebase';
import { getActiveProperty } from '../../../pojo-helpers/pojo-helper';
import DevBuild from '../DevBuild/DevBuild';

function Container(props) {
  // eslint-disable-next-line react/prop-types
  const [open, setOpen] = React.useState(false);
  const [hasDocument, setHasDocument] = React.useState(false);
  const [user, setUser] = React.useState(null);
  const [property, setProperty] = React.useState('0000');
  // Place in a helper, used in App.jsx also
  const isDevEnv = () => process.env.REACT_APP_IS_DEV === 'TRUE';
  const toggleDrawer = (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    // If it's closing & tutorial for hamburger is finished & gl is not, enable.
    setOpen(!open);
  };
  const { areHistoricalsAvailable } = useSelector((state) => state.historicals);
  useEffect(() => {
    onAuthStateChanged(auth, (authUser) => { setUser(authUser); });
    getActiveProperty().then((res) => { setHasDocument(res !== '-1'); setProperty(res); });
  }, []);
  const { children } = props;
  return (
    <div>
      <div className="sticky top-0">
        {
          (user && hasDocument && areHistoricalsAvailable && <HamburgerDrawer user={user} fontSize="large" open={open} toggleDrawer={toggleDrawer} />)
        }
        <div id="property-notification" className="absolute top-0 right-0 mr-4 mt-4 text-sm text-gray-500">
          Property:
          {' '}
          {property}
        </div>
        {isDevEnv() && <DevBuild />}
      </div>
      <div data-testid="container" className="container main-container shadow-lg p-10 mt-5 mb-5">
        {children}
      </div>
    </div>
  );
}

Container.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.object]).isRequired,
};

export default Container;
