import React from 'react';
import {
  TextField,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  IntervalEnum, MonthsEnum, westlandTextField,
} from '../../../pojo-helpers/pojo-helper';
import MenuList from '../../Helper/MenuList/MenuList';
import {
  NumberFormatCustom,
} from '../../Helper/helper-index';

function Interval(props) {
  const {
    values, valueBreakdown, handleSelectChange, handleNumberChange, labels, labelHelpers,
  } = props;

  const classes = westlandTextField();
  return (
    <div className="mt-16 mb-16">
      <div>
        {values.map((value, index) => (
          <div>
            <div className="flex justify-center">
              <TextField
                className={`${classes.root} p-2 w-64`}
                label={labels[0]}
                value={value.numberFormat}
                onChange={(event) => handleNumberChange(event, index)}
                name="numberFormat"
                id="formatted-numberFormat-input"
                variant="outlined"
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />

              <MenuList
                capitalize
                helperText={labelHelpers[0]}
                value={valueBreakdown[index].breakdown}
                name="breakdown"
                inputLabel={labels[1]}
                enumerationObject={IntervalEnum}
                budgetType="interval"
                handleSelectChanges={(event) => handleSelectChange(event, index)}
              />

            </div>
            <div className="flex justify-center">
              <MenuList
                convertToMonth
                helperText={labelHelpers[1]}
                value={valueBreakdown[index].intervalStart}
                name="intervalStart"
                inputLabel={labels[2]}
                enumerationObject={MonthsEnum}
                budgetType="interval"
                handleSelectChanges={(event) => handleSelectChange(event, index)}
              />
            </div>
          </div>
        ))}

      </div>
    </div>
  );
}

Interval.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  labelHelpers: PropTypes.arrayOf(PropTypes.string).isRequired,
  values: PropTypes.arrayOf(PropTypes.shape({
    numberFormat: PropTypes.string.isRequired,
  })).isRequired,
  valueBreakdown: PropTypes.arrayOf(PropTypes.shape({
    breakdown: PropTypes.string.isRequired,
    intervalStart: PropTypes.number.isRequired,
  })).isRequired,
  handleSelectChange: PropTypes.func.isRequired,
  handleNumberChange: PropTypes.func.isRequired,
};

export default Interval;
