class MapHandler {
  constructor() {
    this.budgetAssociations = new Map();
  }

  register(key, value) {
    try {
      this.budgetAssociations.set(key, value);
    } catch (e) {
      throw new Error(`Unexpected Error in Register: ${e}`);
    }
  }

  get(key) {
    try {
      return this.budgetAssociations.get(key);
    } catch (e) {
      throw new Error(`Unexpected Error in Get: ${e}`);
    }
  }
}

export default new MapHandler();
