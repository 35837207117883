import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ErrorContainer from '../../ErrorHandling/ErrorContainer/ErrorContainer';
import { axiosGet } from '../../../pojo-helpers/pojo-helper';
import { CenteredLoader } from '../../Helper/helper-index';

function AxiosGet(props) {
  // eslint-disable-next-line no-unused-vars
  const { Component, historicals, propertyData } = props;
  const [isLoadingBudget, setLoadingBudget] = React.useState(true);
  const [hasErrorBudget, setErrorBudget] = React.useState(false);
  const [returnedData, setReturnedData] = React.useState([{}]);
  const reset = () => { setLoadingBudget(true); setErrorBudget(false); };
  const isLoading = () => isLoadingBudget;
  const hasError = () => hasErrorBudget;
  const fetchData = () => {
    axiosGet(setLoadingBudget, setErrorBudget, setReturnedData, { });
  };
  const retryButton = () => { reset(); fetchData(); };

  // Todo remove static propertyCode
  useEffect(() => {
    reset();
    fetchData();
  }, []);

  return (
    <div>
      {isLoading() && <CenteredLoader />}
      {hasError() && <ErrorContainer retryAction={retryButton} />}
      {!isLoading() && !hasError() && <Component data={returnedData} historicals={historicals} propertyData={propertyData} />}
    </div>
  );
}

AxiosGet.defaultProps = {
  historicals: [{}],
  propertyData: { units: 0, turns: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
};

AxiosGet.propTypes = {
  Component: PropTypes.func.isRequired,
  historicals: PropTypes.arrayOf(PropTypes.shape({})),
  propertyData: PropTypes.shape({
    units: PropTypes.number,
    turns: PropTypes.arrayOf(PropTypes.number),
  }),
};

export default AxiosGet;
