import React from 'react';
import NumberFormat from 'react-number-format';
import {
  TextField,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  monthNumberToDate, westlandTextField,
} from '../../../pojo-helpers/pojo-helper';

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

function Periodic(props) {
  const {
    handleNumberChange, periodicArray, values,
  } = props;

  const half = Math.ceil(periodicArray.length / 2);
  // const half = Math.floor(periodicArray.length >= 6) ? 6 : periodicArray.length;
  const firstPeriodicHalf = periodicArray.slice(0, half);
  const lastPeriodicHalf = periodicArray.slice(half);

  const classes = westlandTextField();
  return (
    <div className="mt-4 mb-16">
      <div className="flex justify-center periodicHeight">
        <div className="grid grid-cols-1 auto-rows-max">
          {
            firstPeriodicHalf.map((period) => (
              <TextField
                key={period}
                className={`${classes.root} p-2 w-64 mt-3`}
                label={monthNumberToDate(period)}
                value={values[period]}
                onChange={handleNumberChange}
                name={`${period}`}
                id={`formatted-periodic-input-${period}`}
                variant="outlined"
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
            ))
          }
        </div>
        <div className="grid grid-cols-1 auto-rows-max">
          {
            lastPeriodicHalf.map((period) => (
              <TextField
                key={`${period}-month`}
                className={`${classes.root} p-2 w-64 mt-3`}
                label={monthNumberToDate(period)}
                value={values[period]}
                onChange={handleNumberChange}
                name={`${period}`}
                id={`formatted-periodic-input-${period}`}
                variant="outlined"
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
            ))
          }
          <TextField
            className={`${classes.root} p-2 w-64 invisible`}
            label="hidden"
            variant="outlined"
          />
        </div>
      </div>
    </div>
  );
}

Periodic.defaultProps = {
  periodicArray: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
};

Periodic.propTypes = {
  handleNumberChange: PropTypes.func.isRequired,
  periodicArray: PropTypes.arrayOf(PropTypes.number),
  values: PropTypes.shape({
    numberFormat: PropTypes.string.isRequired,
    0: PropTypes.string.isRequired,
    1: PropTypes.string.isRequired,
    2: PropTypes.string.isRequired,
    3: PropTypes.string.isRequired,
    4: PropTypes.string.isRequired,
    5: PropTypes.string.isRequired,
    6: PropTypes.string.isRequired,
    7: PropTypes.string.isRequired,
    8: PropTypes.string.isRequired,
    9: PropTypes.string.isRequired,
    10: PropTypes.string.isRequired,
    11: PropTypes.string.isRequired,
  }).isRequired,
};

export default Periodic;
