import React from 'react';
import {
  TextField,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  capitalizeFirstLetter, MonthsEnum, westlandTextField, BreakdownEnumNoQ,
} from '../../../pojo-helpers/pojo-helper';
import {
  NumberFormatCustom,
} from '../../Helper/helper-index';
import MenuList from '../../Helper/MenuList/MenuList';

function Seasonal(props) {
  const {
    values, valueBreakdown, labels, labelHelpers, handleSelectChange, handleNumberChange,
  } = props;

  const classes = westlandTextField();
  return (
    <div className="mt-16 mb-16">
      <div className="flex flex-col justify-center items-center">
        {values.map((value, index) => (
          <div>
            <div className="flex justify-around">
              <div>
                <TextField
                  className={`${classes.root} p-2 w-72`}
                  label={labels[0].replace('SELECTED', capitalizeFirstLetter(valueBreakdown[index].breakdown))}
                  value={value.numberFormat}
                  onChange={(event) => handleNumberChange(event, index)}
                  name="numberFormat"
                  id="formatted-numberFormat-input"
                  variant="outlined"
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                />
              </div>
              <div className="ml-16">
                <MenuList
                  capitalize
                  helperText={labelHelpers[0]}
                  value={valueBreakdown[index].breakdown}
                  name="breakdown"
                  inputLabel={labels[1]}
                  enumerationObject={BreakdownEnumNoQ}
                  budgetType="seasonal"
                  handleSelectChanges={(event) => handleSelectChange(event, index)}
                />
              </div>
            </div>
            <div className="flex justify-around">
              <div className="mt-12">
                <MenuList
                  convertToMonth
                  helperText={labelHelpers[1]}
                  value={valueBreakdown[index].firstMonth}
                  name="firstMonth"
                  inputLabel={labels[2]}
                  enumerationObject={MonthsEnum}
                  budgetType="seasonal-fm-"
                  handleSelectChanges={(event) => handleSelectChange(event, index)}
                />
              </div>
              <div className="mt-12 ml-25">
                <MenuList
                  convertToMonth
                  helperText={labelHelpers[2]}
                  value={valueBreakdown[index].lastMonth}
                  name="lastMonth"
                  inputLabel={labels[3]}
                  enumerationObject={MonthsEnum}
                  budgetType="seasonal-lm-"
                  handleSelectChanges={(event) => handleSelectChange(event, index)}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

Seasonal.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  labelHelpers: PropTypes.arrayOf(PropTypes.string).isRequired,
  values: PropTypes.arrayOf(PropTypes.shape({
    numberFormat: PropTypes.string.isRequired,
  })).isRequired,
  valueBreakdown: PropTypes.arrayOf(PropTypes.shape({
    breakdown: PropTypes.string.isRequired,
    firstMonth: PropTypes.number.isRequired,
    lastMonth: PropTypes.number.isRequired,
  })).isRequired,
  handleSelectChange: PropTypes.func.isRequired,
  handleNumberChange: PropTypes.func.isRequired,
};

export default Seasonal;
