import React from 'react';
import PropTypes, { shape } from 'prop-types';
import MathVerificationEntry from '../MathVerificationEntry/MathVerificationEntry';
import BudgetTitles from '../BudgetTitles/BudgetTitles';
import { MONTHS, STRING_CONF } from '../../../pojo-helpers/pojo-helper';

function MathVerificationColumn(props) {
  const {
    projectionTotals,
  } = props;
  const mathVerificationEntries = MONTHS.map((month) => (
    <MathVerificationEntry
      key={`${month}-math-verification`}
      month={month}
      amount={projectionTotals.filter((breakDownMonth) => breakDownMonth.month === month)[0].amount}
    />
  ));

  return (
    <>
      <div className="flex flex-col">
        <BudgetTitles title={STRING_CONF.MATH_COLUMN_TITLE} />
        { mathVerificationEntries }
      </div>
    </>

  );
}

MathVerificationColumn.propTypes = {
  projectionTotals: PropTypes.arrayOf(shape({
    month: PropTypes.string.isRequired,
    amount: PropTypes.string.isRequired,
    numericAmount: PropTypes.number.isRequired,
  })).isRequired,
};

export default MathVerificationColumn;
