import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl, FormHelperText, InputLabel, MenuItem, Select,
} from '@material-ui/core';
import { capitalizeFirstLetter, monthNumberToDate, westlandTextField } from '../../../pojo-helpers/pojo-helper';

function MenuList(props) {
  const {
    budgetType, enumerationObject, handleSelectChanges,
    helperText, inputLabel, name, value, convertToMonth, capitalize,
  } = props;
  const classes = westlandTextField();

  return (
    <FormControl required className={`${classes.root} ${classes.breakdown}`}>
      <InputLabel id={`${budgetType}-${name}-select-required-label`}>{inputLabel}</InputLabel>
      <Select
        required
        labelId={`${budgetType}-${name}-select-required-label`}
        id={`${budgetType}-${name}-select-required`}
        value={value}
        name={name}
        onChange={handleSelectChanges}
      >
        {
          Object.values(enumerationObject).map((item) => {
            let adjustedItem = item;
            adjustedItem = (convertToMonth) ? monthNumberToDate(item) : item;
            adjustedItem = (capitalize) ? capitalizeFirstLetter(item) : adjustedItem;
            return (<MenuItem key={`${item}-menu-item`} value={item}>{adjustedItem}</MenuItem>);
          })
        }
      </Select>
      <FormHelperText className="w-56">{helperText}</FormHelperText>
    </FormControl>
  );
}

MenuList.defaultProps = {
  capitalize: false,
  convertToMonth: false,
};

MenuList.propTypes = {
  budgetType: PropTypes.string.isRequired,
  capitalize: PropTypes.bool,
  convertToMonth: PropTypes.bool,
  inputLabel: PropTypes.string.isRequired,
  // EnumerationList will be one of the enumeration type held in Collections.js
  // eslint-disable-next-line react/forbid-prop-types
  enumerationObject: PropTypes.object.isRequired,
  handleSelectChanges: PropTypes.func.isRequired,
  helperText: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

export default MenuList;
