import React from 'react';
import {
  CheckCircle, Error, Help, Warning,
} from '@material-ui/icons';
import { green, red, yellow } from '@material-ui/core/colors';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import { Approval } from '@mui/icons-material';
import { CompletionStatus } from '../../../pojo-helpers/Collections';

function convertToArray(glData) {
  if (typeof glData !== 'object' && typeof glData !== 'number') return [];
  return (typeof glData === 'object') ? glData : [glData];
}

function BudgetStatus(props) {
  const { glData, onClick, size } = props;
  const glCompletedDataArray = convertToArray(glData);
  switch (Math.max(...glCompletedDataArray)) {
    case CompletionStatus.Approved:
      return (<IconButton onClick={onClick} size={size}><Approval style={{ color: green[500] }} /></IconButton>);
    case CompletionStatus.Complete:
      return (<IconButton onClick={onClick} size={size}><CheckCircle style={{ color: green[500] }} /></IconButton>);
    case CompletionStatus.Incomplete:
      return (<IconButton onClick={onClick} size={size}><Warning style={{ color: yellow[700] }} /></IconButton>);
    case CompletionStatus.Rejected:
      return (<IconButton onClick={onClick} size={size}><Error style={{ color: red[700] }} /></IconButton>);
    default:
      return (<Help style={{ color: red[700] }} />);
  }
}

BudgetStatus.defaultProps = {
  size: 'medium',
  onClick: () => null,
};

BudgetStatus.propTypes = {
  glData: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.number),
  ]).isRequired,
  onClick: PropTypes.func,
  size: PropTypes.string,
};

export default BudgetStatus;
