import HashMap from 'hashmap';

export default class DataHandler {
  static sortChildren(data) {
    data.forEach((entry) => entry.children.sort((a, b) => parseInt(a.glReference.glCode, 10) - parseInt(b.glReference.glCode, 10)));
  }

  static sortIntoBuckets(data) {
    const sortedData = {};
    data.every((entry) => {
      if (!entry || !Object.keys(entry).length) return false;
      const parentGl = entry.glReference.parentGl.glCode;
      if (!sortedData[parentGl]) sortedData[parentGl] = { parent: entry.glReference.parentGl, children: [] };
      sortedData[parentGl].children.push(entry);
      return true;
    });
    DataHandler.sortChildren(Object.values(sortedData));
    return Object.values(sortedData);
    /*
      Return as
      {
        parent: parent data
        child: [ array of children ]
      }
     */
  }

  static returnBuckets(budgetDataEntryArray) {
    return DataHandler.sortIntoBuckets(budgetDataEntryArray);
  }

  // BudgetDataEntry is optional and if provided will give any glCode without historical information a 0'd field.
  static returnBucketsHistorical(historicalArray, budgetDataEntry) {
    const hashMapHistorical = DataHandler.sortIntoHistoricalBuckets(historicalArray);
    return DataHandler.ensureHistory(hashMapHistorical, budgetDataEntry);
  }

  static sortIntoHistoricalBuckets(data) {
    const hashMapHistorical = new HashMap();
    data.every(async (entry) => {
      if (!entry || !Object.keys(entry).length) return false;
      const parentGl = entry.glReference.parentGl.glCode;
      if (hashMapHistorical.get(parentGl) === undefined) hashMapHistorical.set(parentGl, new HashMap());

      const bucket = hashMapHistorical.get(parentGl);
      // eslint-disable-next-line no-debugger
      // if (entry.glReference.glCode === '610140') debugger;

      if (bucket !== undefined) {
        const bucketGlCode = entry.glReference.glCode;
        if (bucket.get(bucketGlCode) === undefined) bucket.set(bucketGlCode, new Array(12).fill(0));
        const historicalGl = bucket.get(bucketGlCode);
        // The expectation is of a date in format YEAR-MONTH-DAY. Validate? Subtract by one to get array
        const historicalMonth = parseInt(entry.postMonth.split('-')[1], 10) - 1;
        historicalGl[historicalMonth] = entry.amount;
        return true;
      }
      return false;
    });
    return hashMapHistorical;
  }

  static ensureHistory(hashMapHistorical, budgetDataEntry) {
    budgetDataEntry.every((entry) => {
      if (!entry || !Object.keys(entry).length) return false;
      const parentGl = entry.glReference.parentGl.glCode;
      if (hashMapHistorical.get(parentGl) === undefined) hashMapHistorical.set(parentGl, new HashMap());

      const bucket = hashMapHistorical.get(parentGl);
      if (bucket !== undefined) {
        const bucketGlCode = entry.glReference.glCode;
        if (bucket.get(bucketGlCode) === undefined) bucket.set(bucketGlCode, new Array(12).fill(0));
        return true;
      }
      return false;
    });
    return hashMapHistorical;
  }
}
