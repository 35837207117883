import React from 'react';
import { Steps } from 'intro.js-react';
import GoogleSignIn from '../GoogleSignIn/GoogleSignIn';
import { STRING_CONF, TutorialNames, TutorialSteps } from '../../../pojo-helpers/pojo-helper';

function LoginDisplay() {
  return (
    <div className="w-50vw h-50vh flex rounded-lg overflow-hidden shadow-lg bg-white blob">
      <Steps
        enabled={localStorage.getItem(TutorialNames.LoginTutorial) !== '0'}
        steps={TutorialSteps.loginSteps}
        initialStep={TutorialSteps.initialStep}
        options={TutorialSteps.options}
        onExit={() => TutorialSteps.onExit(TutorialNames.LoginTutorial)}
      />
      <div className="ml-24 mt-36 w-1/3">
        <div className="text-5xl font-light tracking-wide ">{STRING_CONF.LOGIN_TITLE}</div>
        <div className="mt-6 text-gray-400">{STRING_CONF.LOGIN_SUBTITLE}</div>
        <div id="login-button" className="mt-4"><GoogleSignIn /></div>
      </div>
      <div className="invoiceBlob w-full h-3/4 mt-48 right-0 mr-32" />
    </div>
  );
}

export default LoginDisplay;
