import axios from 'axios';
import Cookies from 'universal-cookie';
import PropTypes from 'prop-types';
import {
  collection,
  doc, getDoc, getDocs, orderBy, query, setDoc, updateDoc, where,
} from '@firebase/firestore';
import { getFunctions, httpsCallable } from '@firebase/functions';
import { auth, db } from './firebase';
import STRING_CONF from './ConfigStrings';

async function getPages(params, url, property, data = []) {
  return axios.get(url, {
    withCredentials: true,
    params,
  })
    .then((result) => {
      // eslint-disable-next-line no-param-reassign
      params.page = result.data.page.number + 1;
      if (result.data.page.totalPages < params.page) return data;
      // eslint-disable-next-line no-underscore-dangle
      data.push(...result?.data?._embedded[property]);
      return getPages(params, url, property, data);
    });
}

async function getTurns(property) {
  const user = auth.currentUser;
  if (!user) {
    return '1';
  }
  const turnRef = collection(db, 'turns');
  const turnQuery = query(turnRef, where('propertyCode', '==', parseInt(property, 10)), orderBy('month'));
  const turnSnapshot = await getDocs(turnQuery);
  const result = [];
  turnSnapshot.forEach((d) => {
    result.push(d.data().turns);
  });
  return result;
}

async function getUnits(property) {
  const user = auth.currentUser;
  if (!user) {
    return '1';
  }
  const propertiesRef = doc(db, 'properties', property.toString());
  const propertySnap = await getDoc(propertiesRef);
  if (propertySnap.exists()) {
    const { unitCount } = propertySnap.data();
    return unitCount;
  }
  return '1';
}

export async function getReviewsByRole(properties, roleClaims) {
  // If asset, then look to see if you need asset approval; else regional
  let documentQuery;
  const results = [];
  if (roleClaims.asset) {
    documentQuery = query(collection(db, process.env.REACT_APP_REVIEWS),
      where('isReady', '==', true),
      where('needsRegional', '==', false),
      where('needsAsset', '==', true));
  } else if (roleClaims.regional) {
    documentQuery = query(collection(db, process.env.REACT_APP_REVIEWS),
      where('isReady', '==', true),
      where('needsRegional', '==', true));
  } else {
    return results;
  }

  const querySnapshot = await getDocs(documentQuery);
  querySnapshot.forEach((review) => {
    // doc.data() is never undefined for query doc snapshots
    results.push(review.data());
  });
  return results;
}

export async function getProperties(setError = null, setMessage = null) {
  const user = auth.currentUser;

  if (!user) {
    if (setMessage) setMessage(STRING_CONF.ERROR_NO_USER);
    return '-1';
  }
  const userRef = doc(db, 'users', user.email);
  const userSnap = await getDoc(userRef);
  if (userSnap.exists()) {
    const { properties } = userSnap.data();
    if (!properties) {
      if (setError) setError(true);
      if (setMessage) setMessage(STRING_CONF.ERROR_NO_PROPERTIES);
      return '-1';
    }
    if (setError) setError(false);
    return properties;
  }
  if (setError) setError(true);
  if (setMessage) setMessage(STRING_CONF.ERROR_NO_ASSOCIATED_PROPERTIES);
  return '-1';
}

export async function getPropertyData(property, setPropertyData) {
  const turns = await getTurns(property);
  const units = await getUnits(property);
  setPropertyData({ units, turns });
}

export async function getActiveProperty(setError = null, setMessage = null) {
  const user = auth.currentUser;
  if (!user) {
    if (setMessage) setMessage(STRING_CONF.ERROR_NO_USER);
    if (setError) setError(true);
    return '-1';
  }
  const userRef = doc(db, 'users', user.email);
  const userSnap = await getDoc(userRef);
  if (userSnap.exists()) {
    const { activeProperty } = userSnap.data();
    if (!activeProperty) {
      if (setError) setError(true);
      if (setMessage) setMessage(STRING_CONF.ERROR_NO_ACTIVE_PROPERTY);
      return '-1';
    }
    if (setError) setError(false);
    return activeProperty;
  }
  if (setMessage) setMessage(STRING_CONF.ERROR_NO_ASSOCIATED_PROPERTIES);
  if (setError) setError(true);
  return '-1';
}

export function axiosGet(setLoading, setError, setReturnedData, params = {}, url = process.env.REACT_APP_BUDGET_ENTRY_DATA, property = 'budgetDataEntries') {
  return getActiveProperty()
    .then((propCode) => {
      // eslint-disable-next-line no-param-reassign
      params.propertyCode = (params.propertyCode) ? params.propertyCode : propCode;
      return getPages(params, url, property)
        .then((res) => {
          setLoading(false);
          setReturnedData(res);
          return res;
        })
        .catch(() => {
          setError(true);
          setLoading(false);
          return [];
        });
    })
    .catch(() => {
      setError(true);
      setLoading(false);
      return [];
    });
}

export async function getUserData(uid) {
  const docRef = doc(db, 'users', uid);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data();
  }
  return undefined;
}

export async function getManagerEmailByProperty(property) {
  const docRef = doc(db, 'properties', `${property}`);
  const docSnap = await getDoc(docRef);
  let toAddress;
  if (docSnap.exists()) {
    toAddress = docSnap.data().manager;
  }
  return toAddress;
}

export async function getRegionalDetails() {
  const docRef = doc(db, 'properties', `${await getActiveProperty()}`);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    const toAddress = docSnap.data().regional;
    const { displayName } = await getUserData(toAddress);
    return { toAddress, regionalName: displayName };
  }
  return { toAddress: undefined, regionName: undefined };
}

export async function sendEmail(data, callable) {
  const functions = getFunctions();
  // eslint-disable-next-line no-unused-vars
  const addMessage = httpsCallable(functions, callable);

  // The only *required* field is the toAddress, though email type uses different parts of the payload
  const payload = {
    toAddress: process.env.NODE_ENV === 'production' ? data.toAddress : STRING_CONF.DEV_EMAIL,
    glCodes: data.glCodes,
    managerName: data.managerName,
    parentGl: data.parentGl,
    propertyCode: data.propertyCode,
    regionalName: data.regionalName,
    regionalNotes: data.regionalNotes,
  };

  addMessage(payload)
    .then((result) => result)
    .catch((error) => {
      // Getting the Error details.
      const { code } = error;
      const { message } = error;
      const { details } = error;
      console.error(code, message, details);
    });
  return payload;
}

export function addOrAdjustLineItem(payload) {
  const submissionData = payload?.monthly?.months.map((bde) => ({
    id: payload.glCode + payload.property + bde.month,
    submissionPayload: {
      amount: bde.numericAmount,
      completionStatus: payload.completionStatus,
      glCode: payload.glCode,
      manager: payload.user,
      month: bde.month,
      notes: payload.notes,
      property: payload.property,
      reviewId: payload.reviewId,
    },
  }));

  submissionData.forEach((lineItem) => {
    setDoc(doc(db, process.env.REACT_APP_LINE_ITEM, lineItem.id), lineItem.submissionPayload)
      .catch((e) => console.error(e));
  });
}

export async function pushReviewData(payload, merge = false) {
  const payloadId = `${payload.glCode}${payload.property}`;
  const reviewDocument = doc(db, process.env.REACT_APP_REVIEWS, payloadId);
  await setDoc(reviewDocument, payload, { merge })
    .catch((err) => console.error(err));
}

export async function pushCompleteData() {
  /*
    const cityRef = doc(db, 'cities', 'BJ');
    setDoc(cityRef, { capital: true }, { merge: true });
   */
  return {
    glCode: '',
    propertyCode: '',
    month: '',
    amount: '',
    isComplete: '',
    description: '',
    budgetType: '',
    glParent: '',
    parentName: '',
    notes: '',
  };
}

export async function setActiveProperty(property) {
  const user = auth.currentUser;

  const userRef = doc(db, 'users', user.email);
  return updateDoc(userRef, {
    activeProperty: property,
  });
}

// eslint-disable-next-line no-unused-vars
export function axiosPut(data, url) {
  const config = {
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
      'X-XSRF-TOKEN': new Cookies().get('XSRF-TOKEN'),
    },
  };
  return axios.patch(url, data, config)
    .then(async (result) => result)
    .catch((error) => error.response);
}

export function setGlReviewStatus(urls, status) {
  urls.forEach(async (url) => {
    await axiosPut({ isComplete: status }, url);
  });
}

axiosPut.propTypes = {
  data: PropTypes.shape({
    budgetData: PropTypes.shape({
      valueBreakdown: PropTypes.shape({}),
      values: PropTypes.shape({}),
    }).isRequired,
    isComplete: PropTypes.bool.isRequired,
  }).isRequired,
  url:
  PropTypes.string.isRequired,
};
