import React, { useEffect } from 'react';
import { PacmanLoader } from 'react-spinners';
import { STRING_CONF } from '../../../pojo-helpers/pojo-helper';

function CenteredLoader() {
  const text = STRING_CONF.LOADING_MESSAGES;
  const [index, setIndex] = React.useState(Math.floor(Math.random() * text.length));
  useEffect(() => {
    const intervalID = setInterval(() => {
      const numb = Math.floor(Math.random() * text.length);
      setIndex(numb);
    }, 3000);

    return () => clearInterval(intervalID);
  }, []);

  return (
    <div>
      <div style={{ marginLeft: '-50px' }} className="mb-12 flex w-full justify-center">
        <PacmanLoader color="#F2F20D" size="30px" />
      </div>
      <div className="text-xs text-gray-500 flex w-full justify-center">
        {text[index]}
      </div>
    </div>
  );
}

export default CenteredLoader;
