import React from 'react';
import PropType from 'prop-types';
import {
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
} from '@material-ui/core';

function ConfirmationDialogue(props) {
  const {
    dialogueTitle, dialogueContext, dialogueOptionLeft, dialogueOptionRight, open, handleClose, successResult,
  } = props;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{dialogueTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {dialogueContext}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {dialogueOptionLeft}
        </Button>
        <Button onClick={successResult} color="primary" autoFocus>
          {dialogueOptionRight}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmationDialogue.propTypes = {
  dialogueTitle: PropType.string.isRequired,
  dialogueContext: PropType.string.isRequired,
  dialogueOptionLeft: PropType.string.isRequired,
  dialogueOptionRight: PropType.string.isRequired,
  open: PropType.bool.isRequired,
  handleClose: PropType.func.isRequired,
  successResult: PropType.func.isRequired,
};

export default ConfirmationDialogue;
