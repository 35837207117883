import React from 'react';
import GoogleButton from 'react-google-button';
import firebase from 'firebase/compat';
import { signInWithPopup } from '@firebase/auth';
import { auth } from '../../../pojo-helpers/firebase';

function GoogleSignIn() {
  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: 'select_account',
    });
    signInWithPopup(auth, provider)
      .catch((error) => {
        const errorMessage = error.message;
        // TODO Logging
        console.log(errorMessage);
      });
  };

  return (
    <div className="w-1/2">
      <GoogleButton onClick={signInWithGoogle} />
    </div>
  );
}

export default GoogleSignIn;
