/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  historicalData: [],
  areHistoricalsAvailable: false,
};

export const historicalSlice = createSlice({
  name: 'historicals',
  initialState,
  reducers: {
    clearHistorical: (state) => {
      state.historicalData = [];
      state.areHistoricalsAvailable = false;
    },
    setHistorical: (state, action) => {
      state.historicalData = action.payload;
      state.areHistoricalsAvailable = true;
    },
  },
});

// Action creators are generated for each case reducer function
export const { clearHistorical, setHistorical } = historicalSlice.actions;

export default historicalSlice.reducer;
