import { initializeApp } from 'firebase/app';
import { getFirestore } from '@firebase/firestore';
import { getAuth } from '@firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyAqZ8Gi8IzJ5uUeAxna7opdedusDtia_34',
  authDomain: 'budgetappdevelopment.firebaseapp.com',
  projectId: 'budgetappdevelopment',
  storageBucket: 'budgetappdevelopment.appspot.com',
  messagingSenderId: '644772547899',
  appId: '1:644772547899:web:db2370dd3340d86d10fb1e',
  measurementId: 'G-P8CND4B2H9',
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
